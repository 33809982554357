import store from '@/store'

const apiUrl = process.env.VUE_APP_API
const apiUrlAuth = process.env.VUE_APP_API_AUTH

function getApi(url) {
  return getFetchConfig(url)
}

function postApi(url, data) {
  return getFetchConfig(url, 'POST', data)
}

function postApiFile(url, data, contentType = 'text/plain') {
  return getFetchConfigFile(url, 'POST', contentType, data)
}

function postApiAuth(url, data) {
  return getAuthFetchAPI(url, data)
}

function putApi(url, data) {
  return getFetchConfig(url, 'PUT', data)
}

function deleteApi(url, data) {
  return getFetchConfig(url, 'DELETE', data)
}

function getAuthFetchAPI(url, body) {
  return fetch(`${apiUrlAuth}${url}`, fetchConfig('POST', body))
}

async function getFetchConfig(url, method = 'GET', body = {}) {
  const baseUrl = store.getters.toggledToProd ? 'https://api.myselfie.com/portals/v1' : apiUrl
  await store.dispatch('fetchAccessToken')
  const response = await fetch(`${baseUrl}${url}`, fetchConfig(method, body))
  handleRedirectResult(response)
  handleUnauthResponse(response)
  return response
}

async function getFetchConfigFile(url, method = 'GET', contentType, body = {}) {
  const baseUrl = store.getters.toggledToProd ? 'https://api.myselfie.com/portals/v1' : apiUrl
  await store.dispatch('fetchAccessToken')
  const response = await fetch(`${baseUrl}${url}`, fetchConfigFile(method, contentType, body))
  handleUnauthResponse(response)
  return response
}

function handleUnauthResponse(response) {
  if (response.status === 401) {
    sessionStorage.clear()
    document.cookie = 'cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    store.commit('logout')
    location.replace(store.getters.langModule.globals.baseUrl)
  }
}

function handleRedirectResult(response) {
  if (response.status === 412) {
    location.href = '/#/logout'
  }
}

function fetchConfig(apiMethod, apiBody = {}) {
  const token = store.getters.accessToken !== null ? store.getters.accessToken : store.getters.anonAccessToken
  return apiMethod === 'GET'
    ? {
      method: apiMethod,
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', GalleryVersion: store.getters.currentGalleryVersion.toString() }
    }
    : {
      method: apiMethod,
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(apiBody)
    }
}

function fetchConfigFile(apiMethod, contentType = 'text/plain', apiBody = {}) {
  const token = store.getters.accessToken !== null ? store.getters.accessToken : store.getters.anonAccessToken
  return {
    method: apiMethod,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': contentType },
    body: apiBody
  }
}

export default {
  postApi: postApi,
  postApiFile: postApiFile,
  getApi: getApi,
  deleteApi: deleteApi,
  putApi: putApi,
  postApiAuth: postApiAuth
}
