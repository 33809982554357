import { mapGetters, mapMutations, mapActions } from 'vuex'
import { validateEmail, validatePhone, DeleteMode, HideMode, randomNumber, getBackgroundSize, sleep, Stopwatch } from '@/GeneralMethods'
import MysTextBox from '@/components/MysTextBox'
import MysButton from '@/components/MysButton'
import MysCopyButton from '@/components/MysCopyButton'
import Chevron from '@/components/Chevron'
import ChevronMain from '@/components/ChevronMain'
import OrderInformation from '@/components/OrderInformation'
import Menu from '@/components/Menu'
import MobileHeader from '@/components/MobileHeader'
import LangPicker from '@/components/LangPicker'
import LoadingDots from '@/components/LoadingDots'
export default {
  components: {
    MysTextBox,
    MysButton,
    MysCopyButton,
    Chevron,
    ChevronMain,
    OrderInformation,
    Menu,
    MobileHeader,
    LangPicker,
    LoadingDots,
    Modal: () => import('@/components/Modal'),
    ModalSmall: () => import('@/components/ModalSmall'),
    GalleryModal: () => import('@/components/GalleryModal'),
    DownloadGalleryModal: () => import('@/components/DownloadGalleryModal'),
    SlidePanelModal: () => import('@/components/SlidePanelModal'),
    SlideshowModal: () => import('@/components/SlideshowModal'),
    ShareGalleryModal: () => import('@/components/ShareGalleryModal'),
    InformDeleteRequest: () => import('@/components/InformDeleteRequest'),
    OverlayPart: () => import('@/components/OverlayPart')
  },
  computed: {
    ...mapGetters([
      'toggledToProd',
      'currentGalleryVersion',
      'previewScreenData',
      'previewPictureData',
      'apiBaseUrl',
      'isDev',
      'accessToken',
      'isGalleryOwner',
      'imageStateChanging',
      'galleryActiveImageIndex',
      'showDeleteInform',
      'galleryIsActive',
      'imageOpened',
      'selectedImg',
      'imgChildHeight',
      'showSlideModal',
      'showSlideAllGalleries',
      'showSlideOneGallery',
      'images',
      'currentOrderNumber',
      'currentShopDomain',
      'loginName',
      'loginPassword',
      'externalFullscreenActivated',
      'newImageAdded',
      'lastFile',
      'lastDate',
      'currentYPos',
      'orders',
      'currentOrder',
      'showGalleryModal',
      'slideShutdown',
      'isSafari',
      'isFireFox',
      'isChrome',
      'isMobile',
      'imageChanging',
      'lastFrameWidth',
      'lastFrameHeight',
      'extraWaitTime',
      'firstGalleryFetched',
      'jwtOrderObject',
      'loginExpire',
      'anonAccessToken',
      'language',
      'languageCode',
      'menuFullscreen',
      'langModule',
      'positionCross',
      'langExpanded',
      'menuClosed',
      'galleryDate',
      'overlayPictureState',
      'overlayScreenState',
      'lastOverlayTemplate',
      'overlayScreenLoading',
      'overlayPictureLoading',
      'overlayReady',
      'newImagesAvailable'
    ]),
    externalSlide() {
      return this.$route.path.includes('slideshow')
    }
  },
  data () {
    return {
      keypressEventMethod: null
    }
  },
  methods: {
    ...mapMutations([
      'changeToggledToProd',
      'changeImages',
      'changeOrders',
      'changeCurrentGalleryVersion',
      'changePreviewScreenData',
      'changePreviewPictureData',
      'logout',
      'showSlide',
      'changeJwtOrderObject',
      'exitModal',
      'exitSlideshow',
      'exitImage',
      'registerNewOrder',
      'changeIsGalleryOwner',
      'changeGalleryDate',
      'changeMenuClosed',
      'changeLangExpanded',
      'changePositionCross',
      'changeMenuFullscreen',
      'changeLanguage',
      'changeFirstGalleryFetched',
      'changeExtraWaitTime',
      'newImage',
      'changeCurrentOrder',
      'changeSlideShutdown',
      'changeCurrentYPos',
      'changeShowGalleryModal',
      'changeNewImageAdded',
      'changeExternalFullscreenActivated',
      'changeShowSlideModal',
      'changeLastFile',
      'changeLastDate',
      'changeImageState',
      'changeCurrentOrderNumber',
      'changeCurrentShopDomain',
      'changeShowDeleteInform',
      'changeImageOpened',
      'changeImgChildHeight',
      'changeOverlayPictureState',
      'changeOverlayScreenState',
      'changeLastOverlayTemplate',
      'destroyOverlayStates',
      'changeOverlayScreenLoading',
      'changeOverlayPictureLoading',
      'changeGalleryActiveImageIndex',
      'changeSelectedImg',
      'changeNewImagesAvailable'
    ]),
    ...mapActions([
      'initStore',
      'registerDefaultLanguage',
      'registerLogin',
      'newImagesMetaData',
      'handleExtraWaitTime',
      'changeFrameSize',
      'handleExpiration',
      'refreshToken',
      'fetchAccessToken',
      'handleAnonResponseResult',
      'sortOrders',
      'fetchOrders'
    ]),
    setSelectedImg (img) {
      if (img === null) return

      this.changeSelectedImg(img)
      this.setModes()
    },
    async adjustCover (backgroundImgContainer) {
      const backgroundImgFrame = document.getElementById('backgroundImgFrame')
      if (backgroundImgContainer && backgroundImgFrame) {
        let containerSize = getBackgroundSize(backgroundImgContainer)
        const sw = new Stopwatch()
        while (isNaN(containerSize.height) && sw.milliseconds < 5000) {
          await sleep(1)
          containerSize = getBackgroundSize(backgroundImgContainer)
        }

        if (containerSize.width > 0) this.changeFrameSize(containerSize)
      }
    },
    setModes () {
      if (this.selectedImg === null) return

      if (!this.selectedImg.hideMode) this.selectedImg.hideMode = this.selectedImg.hidden ? HideMode.Hidden : HideMode.Hide

      this.selectedImg.deleteMode = DeleteMode.Delete
    },
    formatDate (date, bindChar = '.') {
      const padTo2Digits = num => num.toString().padStart(2, '0')
      return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(bindChar)
    },
    sortedGalleryImages(gallery) {
      return gallery.images.filter(() => true).sort((a, b) => {
        const dateTimeA = a.name.substring(a.name.indexOf('_') + 1, a.name.indexOf('+'))
        const dateTimeB = b.name.substring(b.name.indexOf('_') + 1, b.name.indexOf('+'))

        if (gallery.metadata.sortNewestFirst) {
          return dateTimeA < dateTimeB ? 1 : -1
        } else {
          return dateTimeA > dateTimeB ? 1 : -1
        }
      })
    },
    setImagesMetadata(index, metaData) {
      const dtoObject = {
        index: index,
        metaData: metaData
      }
      this.newImagesMetaData(dtoObject)
    },
    getImageCount() {
      if (this.images === null || this.images === {} || this.images === undefined) return 0

      const keys = Object.keys(this.images)
      const keyLen = keys.length
      if (keyLen === 0) return 0

      let count = 0
      for (let i = 0; i < keyLen; i++) {
        const meta = this.images[keys[i]].metadata
        if (meta) count += meta.imageCount
      }

      return count
    },
    On_SlideRight () {
      const backgroundImgContainer = this.getBackgroundContainer()
      if (backgroundImgContainer === null) return

      const sortedImgs = this.gallery.images
      if (this.galleryActiveImageIndex >= sortedImgs.length - 1) {
        this.changeGalleryActiveImageIndex(0)
      } else {
        this.changeGalleryActiveImageIndex(this.galleryActiveImageIndex + 1)
      }
      this.nextImage(sortedImgs)
    },
    On_SlideLeft () {
      const backgroundImgContainer = this.getBackgroundContainer()
      if (backgroundImgContainer === null) return

      const sortedImgs = this.gallery.images
      if (this.galleryActiveImageIndex <= 0) {
        this.changeGalleryActiveImageIndex(sortedImgs.length - 1)
      } else {
        this.changeGalleryActiveImageIndex(this.galleryActiveImageIndex - 1)
      }
      this.nextImage(sortedImgs)
    },
    On_SlideRandom () {
      const backgroundImgContainer = this.getBackgroundContainer()
      if (backgroundImgContainer === null) return

      const sortedImgs = this.gallery.images
      const rndIndex = randomNumber(0, sortedImgs.length)
      this.changeGalleryActiveImageIndex(rndIndex)
      this.nextImage(sortedImgs)
    },
    getBackgroundContainer () {
      if (!this.imageOpened) return null

      const backgroundImgContainer = document.getElementById('backgroundImgContainer')

      if (!backgroundImgContainer) return null

      return backgroundImgContainer
    },
    nextImage (selectedImages) {
      const clickedImg = selectedImages[this.galleryActiveImageIndex]
      this.setSelectedImg(clickedImg)
    },
    emailIsValid (email) {
      return validateEmail(email)
    },
    phoneIsValid (phone) {
      return validatePhone(phone)
    },
    getFrom (from, source, defaultResult = '') {
      const ix = source.lastIndexOf(from)
      return ix === -1 ? defaultResult : source.slice(ix + 1)
    },
    getBefore (from, source, defaultResult = '') {
      const ix = source.lastIndexOf(from)
      return ix === -1 ? defaultResult : source.slice(0, ix)
    },
    setYPos() {
      const scrollEle = document.scrollingElement
      if (scrollEle) {
        const top = scrollEle.scrollTop
        this.changeCurrentYPos(top)
        this.hideLayoutScroll(true)
      }
    },
    hideLayoutScroll(hide) {
      const layoutContainer = document.getElementById('layoutContainer')
      if (layoutContainer) {
        layoutContainer.style.overflowX = hide ? 'hidden' : 'visible'
      }
    },
    originalUrl (item) {
      const query = item.original
      const orderNr = this.currentOrderNumber
      const shopDomain = this.currentShopDomain
      const baseUrl = this.apiBaseUrl
      const fileName = item.name
      return `${baseUrl}/${shopDomain}/${orderNr}/original/${fileName}?${query}`
    },
    webUrl (item) {
      const query = item.web
      const orderNr = this.currentOrderNumber
      const shopDomain = this.currentShopDomain
      const baseUrl = this.apiBaseUrl
      const fileName = item.name
      return `${baseUrl}/${shopDomain}/${orderNr}/web/${fileName}?${query}`
    },
    thumbUrl (item) {
      const query = item.thumbnail
      const orderNr = this.currentOrderNumber
      const shopDomain = this.currentShopDomain
      const baseUrl = this.apiBaseUrl
      const fileName = item.name
      return `${baseUrl}/${shopDomain}/${orderNr}/thumbnail/${fileName}?${query}`
    },
    addMinutes (date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    },
    handlePasswordRetryDate(email, newChangeDate = null) {
      const key = `passwordChangeDate${email}`

      if (newChangeDate !== null) {
        localStorage[key] = newChangeDate
        return newChangeDate
      }

      const value = localStorage.getItem(key)
      if (value === null) return null

      return new Date(Date.parse(value))
    },
    shortDate (date) {
      return date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
    },
    dateIsValid (date) {
      if (date === null) return false

      return date instanceof Date && !isNaN(date)
    },
    getHideText(hideMode) {
      const lm = this.langModule
      switch (hideMode) {
        case HideMode.Hide:
          return lm.buttons.hideDefault
        case HideMode.Hidden:
          return lm.buttons.hidden
        case HideMode.Hiding:
          return lm.buttons.hiding
        case HideMode.Showing:
          return lm.buttons.showing
        case HideMode.HideMultiple:
          return lm.buttons.hideMultiple
        case HideMode.ShowMultiple:
          return lm.buttons.showMultiple
        case HideMode.RequestHide:
          return lm.buttons.deleteRequest
        case HideMode.ConfirmHide:
          return lm.buttons.confirm
        default: return ''
      }
    },
    getDeleteText(deleteMode) {
      const lm = this.langModule
      switch (deleteMode) {
        case DeleteMode.Delete:
          return lm.buttons.deleteDefault
        case DeleteMode.Deleting:
          return lm.buttons.deleting
        case DeleteMode.DeleteMultiple:
          return lm.buttons.deleteMultiple
        case DeleteMode.RequestDelete:
          return lm.buttons.deleteRequest
        case DeleteMode.ConfirmDelete:
          return lm.buttons.confirm
        default: return ''
      }
    },
    getDeleteTextAnon(deleteMode) {
      const lm = this.langModule
      switch (deleteMode) {
        case DeleteMode.ConfirmDelete:
          return lm.buttons.confirm
        default: return lm.buttons.deleteRequest
      }
    },
    observeElement(element, observer, withSubtree = true) {
      if (!element) return
      const observeConfig =
      {
        attributes: false,
        childList: true,
        subtree: withSubtree,
        characterData: false
      }
      observer.observe(element, observeConfig)
    }
  }
}
