import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './styles/app.scss'
import VueClipboard from 'vue-clipboard2'
import router from './router'
import Modal from '@/components/Modal'
import DefaultLayout from '@/layouts/DefaultLayout'
import BootstrapVue from 'bootstrap-vue'
import Mixins from '@/Mixins'

Vue.config.productionTip = false
Vue.mixin(Mixins)
Vue.component(Modal)
Vue.component(DefaultLayout)
Vue.use(BootstrapVue)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
