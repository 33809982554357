<template>
  <div class="text-center" v-bind:style="{minWidth: !isMobile ? '650px' : 'inherit'}">
    <LoadingDots v-if="initialOrderLoadInProgress && !isError" style="top: 50%; left: 50%; transform: translate(-50%,-50%);" :size="25"></LoadingDots>
    <div v-if="isError" class="d-flex flex-grow-1 flex-column justify-content-center align-items-center w-100 w-md-50 w-xl-30 mx-auto h-100 px-3" style="padding-top:80px;">
      <h3 class="mb-5">{{langModule.globals.authFailed}}</h3>
    </div>
    <div v-if="noOrders" class="d-flex flex-grow-1 flex-column justify-content-center align-items-center w-100 w-md-50 w-xl-30 mx-auto h-100 px-3" style="padding-top:80px;">
      <h3 class="mb-5">{{langModule.pages.orders.noOrders}}</h3>
    </div>
    <div class="card p-1 p-md-3 my-5" v-for="(order, index) in orders" :key="index">
      <div v-if="order !== null">
        <div v-if="!isMobile" class="navButtonWrap">
          <MysButton style="width:19.9%; min-width:250px;" :isDisabled="order.expired && !isDev" :text="langModule.buttons.navToGallery" theme="secondary" v-on:click="openOrder(order)"></MysButton>
        </div>
        <div class="card-header bg-white border-0 user-select-none" style="display: grid; justify-content: space-between;">
          <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 20px;">
            <OrderInformation :order="order"></OrderInformation>
            <div class="overlayButtonWrap"><MysButton v-on:click="openOverlay(order)" style="float: right; width: 85px; min-width: 80px; height: 30px;" :MinWidth="false" :isDisabled="order.expired && !isDev" theme="secondary" text="Overlay"></MysButton></div>
          </div>
          <div v-if="isMobile" class="mb-2" style="cursor: default;">
            <div class="navButtonWrapMobile"><MysButton style="min-width:100%; width:309px;" :ready="order.open" :isDisabled="order.expired && !isDev" theme="secondary" :text="langModule.buttons.navToGallery" v-on:click="openOrder(order)"></MysButton></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import apiservice from '@/services/apiservice'
  export default {
    name: 'Orders',
    data() {
      return {
        initialOrderLoadInProgress: true,
        isError: false,
        noOrders: false
      }
    },
    methods: {
      async init() {
        if (this.orders.length > 0) {
          this.initialOrderLoadInProgress = false
          return
        }

        const ordersResponse = await apiservice.getApi('/user/galleryorders')
        if (ordersResponse.status >= 300) this.isError = true

        if (this.isError) return

        await this.fetchOrders()

        if (this.orders.length === 0) this.noOrders = true
      },
      openOrder(order) {
        if (!order) return

        this.registerNewOrder(order)
        this.$router.push(`/gallery/${this.currentShopDomain}/${this.currentOrderNumber}`)
      },
      openOverlay(order) {
        if (!order) return

        this.registerNewOrder(order)
        this.$router.push(`/overlay/${this.currentShopDomain}/${this.currentOrderNumber}`)
      }
    },
    async mounted() {
      await this.init()
      this.initialOrderLoadInProgress = false
    }
  }
</script>
<style scoped>
  .navButtonWrap {
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 103px;
    flex-direction: row-reverse;
    display: flex;
    right: 16px;
  }

  .navButtonWrapMobile {
    text-align: left;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  }

  .overlayButtonWrap {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 99;
  }
</style>
