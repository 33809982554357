<template>
  <transition name="fade">
    <div id="menuFrame" v-if="menuVisible" class="menu" v-show="show">
      <div class="menu-content h-100">
        <div id="logoContainer" class="mw-100 h-100 d-flex justify-content-between flex-column px-4">
          <div class="justify-content-between d-flex py-4 align-items-center" style="padding-bottom: 3.5rem !important; max-height: 112px;">
            <a :href="langModule.globals.baseUrl">
            <img alt="" src="./../assets/myselfie_logo_gray.svg" style="height: 190px; position: relative; top: -2px" v-bind:style="{ left: positionCross ? '-34px' : '-71px'}">
            </a>
            <img id="mobileCross" src="./../assets/cross.svg" width="30" height="30" style="top: 23px" class="close pointer" @click="$parent.hideMenuFn()" v-bind:style="{ right: positionCross ? '46px' : '8px'}" />
          </div>
          <div :class="!menuFullscreen ? 'pl-3' : ''">
            <ul class="list-unstyled">
              <li @click="$parent.hideMenuFn()" class="mb-4">
                <router-link :to="{name : 'OrdersView'}">{{langModule.menu.overview}}</router-link>
              </li>
              <li @click="$parent.hideMenuFn()" class="mb-4">
                <router-link :to="{name : 'ContactView'}">{{langModule.menu.contact}}</router-link>
              </li>
              <li class="mb-4">
                <a :href="langModule.globals.baseUrl" target="_blank">{{langModule.globals.companyPage}}</a>
              </li>
            </ul>
            <div id="menuLangWrap" v-bind:style="{justifyContent: menuFullscreen ? 'center' : 'flex-start' }">
              <LangPicker></LangPicker>
            </div>
          </div>
          <div class="px-5 mb-3" style="display: flex; justify-content: center; margin-top: 10px;">
            <MysButton theme="secondary" :text="langModule.buttons.logout" :small="true" :MinWidth="false" class="btn-block" style="min-width:0px;width:176px;" @click="doLogout"></MysButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  let vm = null
  const menuObserver = new ResizeObserver(entries => {
    const [entry] = entries
    const menuFrame = entry.target

    if (menuFrame) {
      const menuWidth = menuFrame.clientWidth
      const docWidth = document.body.clientWidth
      vm.changeMenuFullscreen(menuWidth >= docWidth)

      const burgerIcon = document.getElementById('burgerIcon')
      if (burgerIcon) {
        const burgerRight = burgerIcon.getBoundingClientRect().right
        vm.changePositionCross((docWidth - 20) > burgerRight)
      }
      vm.changeMenuClosed(menuFrame.className === 'menu closed')
    }
  })

  export default {
    name: 'Menu',
    props: {
      show: {
        required: true
      }
    },
    computed: {
      menuVisible() {
        return !this.externalSlide && !this.showSlideModal
      }
    },
    beforeMount() {
      vm = this
    },
    updated() {
      this.observeMenu()
    },
    mounted() {
      this.observeMenu()
    },
    methods: {
      doLogout() {
        this.logout()
        if (this.isDev) this.$router.push('/login')
        else location.replace(this.langModule.globals.baseUrl)
      },
      observeMenu() {
        const menuFrame = document.getElementById('menuFrame')
        if (menuFrame) menuObserver.observe(menuFrame)
      }
    }
  }
</script>

<style scoped>
  .pointer {
    position: absolute;
    right: 8px;
  }

  a {
    width: 250px;
    white-space: normal;
    word-break: break-word;
    display: inline-block;
  }

  #menuLangWrap {
    display: flex;
    position: relative;
  }
</style>
