<template>
  <div id="langContainer" class="langContainerView">
    <div :class="remTrans ? 'langWrapNoTrans' : 'langWrap'" id="daWrap" v-bind:style="{left: danishLeft, zIndex: language === 'da' ? '99' : '0', opacity: language === 'da' ? '1' : langExpanded ? '.5' : '0.05'}"><img alt="" src="./../assets/icons8-denmark_exp.svg" id="da" @click="langClick"></div>
    <div :class="remTrans ? 'langWrapNoTrans' : 'langWrap'" id="enWrap" v-bind:style="{left: englishLeft, zIndex: language === 'en' ? '99' : '0', opacity: language === 'en' ? '1' : langExpanded ? '.5' : '0.05'}"><img alt="" src="./../assets/icons8-great_britain_exp.svg" id="en" @click="langClick"></div>
    <div :class="remTrans ? 'langWrapNoTrans' : 'langWrap'" id="deWrap" v-bind:style="{left: germanLeft, zIndex: language === 'de' ? '99' : '0', opacity: language === 'de' ? '1' : langExpanded ? '.5' : '0.05'}"><img alt="" src="./../assets/icons8-germany_exp.svg" id="de" @click="langClick"></div>
    <div :class="remTrans ? 'langWrapNoTrans' : 'langWrap'" id="seWrap" v-bind:style="{left: swedishLeft, zIndex: language === 'sv' ? '99' : '0', opacity: language === 'sv' ? '1' : langExpanded ? '.5' : '0.05'}"><img alt="" src="./../assets/icons8-sweden_exp.svg" id="sv" @click="langClick"></div>
  </div>
</template>

<script>
  export default {
    name: 'LangPicker',
    computed: {
      midCollapse() {
        return this.$route.path === '/login' || this.menuFullscreen || this.isMobile
      },
      danishLeft() {
        return this.langExpanded ? '0' : !this.midCollapse ? '0' : '65px'
      },
      englishLeft() {
        return this.langExpanded ? '0' : !this.midCollapse ? '-43px' : '22px'
      },
      germanLeft() {
        return this.langExpanded ? '0' : !this.midCollapse ? '-86px' : '-21px'
      },
      swedishLeft() {
        return this.langExpanded ? '0' : !this.midCollapse ? '-129px' : '-64px'
      }
    },
    watch: {
      midCollapse() {
        this.remTrans = true
      }
    },
    data() {
      return {
        remTrans: false
      }
    },
    methods: {
      langClick(e) {
        this.remTrans = false
        if (this.langExpanded) {
          const selectedLang = e.target.id
          this.changeLanguage(selectedLang)
          localStorage.setItem('currentLang', selectedLang)
        }
        this.changeLangExpanded(!this.langExpanded)
      }
    }
  }
</script>

<style scoped>
  .langContainerView {
    gap: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .langWrap {
    cursor: pointer;
    position: relative;
    width: 28px;
    height: 28px;
    transition: all 0.3s ease-in-out;
  }

  .langWrapNoTrans {
    cursor: pointer;
    position: relative;
    width: 28px;
    height: 28px;
  }
</style>
