<template>
  <div id="overlayContainerWrap">
    <div id="confirmDialogWrap">
      <dialog id="confirmDialog" :open="false">
        <p style="padding-bottom: 10px;">{{langModule.pages.overlay.confirmDeleteAll}}</p>
        <form method="dialog">
          <div id="confirmButtons">
            <MysButton :text="langModule.globals.yes" :MinWidth="false" theme="secondary" @click="deleteOverlay()" style="float: left; width: 85px; min-width: 80px; height: 30px;"></MysButton>
            <MysButton :text="langModule.globals.no" :MinWidth="false" theme="secondary" @click="resetOverlayDeletions()" style="float: left; width: 85px; min-width: 80px; height: 30px;"></MysButton>
          </div>
        </form>
      </dialog>
    </div>

    <div v-if="!showDialog" id="screenPreview" v-bind:style="{ display: previewVisible ? 'flex' : 'none' }">
      <img v-if="previewScreenData !== null" :src="previewScreenData" style="object-fit: cover;" width="874" />
    </div>
    <div v-if="!showDialog" id="picturePreview" v-bind:style="{ display: previewVisible ? 'flex' : 'none' }">
      <img v-if="previewPictureData !== null" :src="previewPictureData" style="object-fit: cover;" width="874" />
    </div>
    <div v-if="!overlayReady && !showDialog || deletingOverlay" id="loadingWrap"><LoadingDots style="position: absolute !important;" :size="25"></LoadingDots></div>
    <div v-if="shouldRender" id="overlayContainer">
      <div id="previewButtonContainer"><MysButton text="Preview" :isDisabled="!loadedPreviews" :MinWidth="false" theme="secondary" @click="showOverlayPreview" style="float: right; width: 85px; min-width: 80px; height: 30px;"></MysButton></div>
      <div class="select-dropdown">
        <select @change="templateChanged" name="templates" id="selectionTemplates">
          <option value="Custom">{{langModule.pages.overlay.templateCustom}}</option>
          <option value="Halloween">Halloween</option>
        </select>
      </div>

      <div class="textSection">
        <p>{{langModule.pages.overlay.header}}</p>
        <p>{{langModule.pages.overlay.screenDesc}}<strong>1920x1200</strong></p>
      </div>

      <div id="screenWrap" class="overlayImageWrap">
        <MysButton :text="langModule.buttons.deleteAll" theme="ternary" iconClass="smallIconSecondary" @click="deleteOverlay('overlayscreen')" icon="/trashcan.svg" style="background-color: #FFFFFF; border: 1px solid black; height: 30px;" v-bind:style="{width: getWidth, minWidth: getWidth}"></MysButton>
        <OverlayPart ContainerId="overlayscreen" Width="auto" Height="650px"></OverlayPart>
      </div>
      <div class="textSection" style="top:925px">
        <p>{{langModule.pages.overlay.imageDesc}}<strong>3264x2448</strong></p>
      </div>
      <div id="pictureWrap" class="overlayImageWrap">
        <MysButton :text="langModule.buttons.deleteAll" theme="ternary" iconClass="smallIconSecondary" @click="deleteOverlay('overlaypicture')" icon="/trashcan.svg" style="background-color: #FFFFFF; border: 1px solid black; height: 30px;" v-bind:style="{width: getWidth, minWidth: getWidth}"></MysButton>
        <OverlayPart ContainerId="overlaypicture" Width="auto" Height="730px"></OverlayPart>
      </div>
    </div>
  </div>
</template>
<script>
  import apiservice from '@/services/apiservice'
  export default {
    name: 'Overlay',
    data: () => {
      return {
        template: '',
        observer: null,
        showPreview: false,
        loadedPreviews: false,
        overlayScreenReset: false,
        overlayPictureReset: false,
        deletingOverlay: false
      }
    },
    computed: {
      shouldRender() {
        return this.overlayScreenState !== null && this.overlayPictureState !== null && this.TABS !== null && this.TOOLS !== null
      },
      previewVisible() {
        return this.showPreview && this.previewPictureData !== null && this.previewScreenData !== null && this.overlayReady
      },
      showDialog() {
        return this.overlayScreenReset || this.overlayPictureReset
      },
      getWidth() {
        switch (this.language) {
          case 'de': return '165px'
          case 'en': return '130px'
          case 'sv': return '140px'
          case 'da': return '120px'
          default: return '120px'
        }
      }
    },
    watch: {
      overlayReady(value) {
        this.overlayProcessing(value)
      },
      showDialog(value) {
        this.overlayProcessing(!value)
      }
    },
    methods: {
      overlayProcessing(value) {
        const overlayContainer = document.getElementById('overlayContainer')
        if (overlayContainer) {
          const style = overlayContainer.style
          style.top = value ? '50px' : '-9999px'
        }
      },
      resetOverlayDeletions() {
        this.overlayScreenReset = false
        this.overlayPictureReset = false
      },
      async deleteOverlay(type = '') {
        if (type.length === 0) {
          if (this.showDialog) {
            this.deletingOverlay = true
            const deleteType = this.overlayScreenReset ? 'screen' : 'picture'
            await apiservice.deleteApi(`/orders/${this.currentOrderNumber}/${this.currentShopDomain}/${deleteType}/resetoverlay`, {})
            location.reload()
          }
        }

        if (type === 'overlayscreen') this.overlayScreenReset = true
        if (type === 'overlaypicture') this.overlayPictureReset = true
        if (type.length > 0) document.getElementById('confirmDialog').open = true
      },
      showOverlayPreview() {
        if (!this.loadedPreviews) return

        this.showPreview = !this.showPreview
      },
      async loadPreviews() {
        await Promise.all(
          [
            this.setPreviewData('Screen'),
            this.setPreviewData('Picture')
          ])
      },
      async setPreviewData(type) {
        switch (type) {
          case 'Picture':
            if (this.previewPictureData !== null) return
            break
          case 'Screen':
            if (this.previewScreenData !== null) return
            break
          default: throw new Error(`Preview ${type} not yet supported`)
        }

        const previewResponse = await apiservice.getApi(`/orders/overlaypreview?template=${type}`)
        switch (type) {
          case 'Picture':
            this.changePreviewPictureData(await previewResponse.text())
            break
          case 'Screen':
            this.changePreviewScreenData(await previewResponse.text())
            break
          default: throw new Error(`Preview ${type} not yet supported`)
        }
      },
      async setStateFile(type) {
        const tpl = this.template !== '' ? this.template : this.lastOverlayTemplate
        const templateParm = tpl === '' || tpl === 'Custom' ? '' : `?template=${tpl}`
        const fileResponse = await apiservice.getApi(`/orders/${this.currentOrderNumber}/${this.currentShopDomain}/${type}${templateParm}`)
        const overlayState = await fileResponse.json()

        switch (type) {
          case 'overlayscreen':
            this.changeOverlayScreenState(overlayState)
            break
          case 'overlaypicture':
            this.changeOverlayPictureState(overlayState)
            break
          default: throw new Error(`Overlay ${this.containerId} not yet supported`)
        }
      },
      async setStateFiles() {
        await Promise.all(
          [
            this.setStateFile('overlayscreen'),
            this.setStateFile('overlaypicture')
          ])
      },
      async templateChanged(e) {
        const value = e.target.value
        if (value === this.template) return
        this.template = value
        await this.setStateFiles()
        this.changeLastOverlayTemplate(value)
      }
    },
    beforeDestroy() {
      if (this.observer !== null) this.observer.disconnect()
      this.destroyOverlayStates()
    },
    beforeMount() {
      const orderNr = this.$route.params.orderNumber
      const shopDomain = this.$route.params.shopDomain

      if (this.currentOrderNumber !== orderNr) this.changeCurrentOrderNumber(orderNr)
      if (this.currentShopDomain !== shopDomain) this.changeCurrentShopDomain(shopDomain)
    },
    async mounted() {
      await this.setStateFiles()
      const selectionTemplate = document.getElementById('selectionTemplates')
      const selectionTemplateOptions = selectionTemplate.options
      const len = selectionTemplateOptions.length
      for (let i = 0; i < len; i++) {
        const selectionTemplate = selectionTemplateOptions[i]
        if (selectionTemplate.value === this.lastOverlayTemplate) {
          selectionTemplate.selected = true
          this.template = this.lastOverlayTemplate
        }
      }

      this.observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          for (const div of mutation.target.querySelectorAll('div')) {
            const cn = div.className
            if (cn.endsWith('SfxModal-root')) {
              for (const button of div.querySelectorAll('button')) {
                if (['Save', 'Speichern'].includes(button.textContent)) {
                  button.click()
                }
              }
            }
          }
        }
      })
      this.observeElement(document.body, this.observer, false)
      await this.loadPreviews()
      this.loadedPreviews = true
    }
  }
</script>
<style scoped>
  p {
    line-height: 1.375;
    white-space: pre-wrap;
    font-family: "proxima-nova", sans-serif;
    font-size: 18px;
  }

  .textSection {
    max-width: 969px;
    min-width: 969px;
    position:absolute;
  }

  #loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
  }

  #confirmButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: space-evenly
  }

  #confirmDialog {
    border: 2px solid black;
  }

  #confirmDialogWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
  }

  #screenPreview {
    left: 93px;
    top: 355px;
    position: absolute;
    z-index: 1;
    width: 873px;
    height: 490px;
    display: flex;
    max-width: 873px;
    max-height: 490px;
  }

  #picturePreview {
    left: 93px;
    top: 1115px;
    position: absolute;
    z-index: 1;
    width: 873px;
    height: 570px;
    display: flex;
    max-width: 873px;
    max-height: 570px;
  }

  #screenWrap{
      top: 180px;
  }

  #pictureWrap {
    top: 260px;
  }

  #previewButtonContainer {
    position: absolute;
    padding-left: 210px;
    padding-top: 5px;
  }

  #overlayContainerWrap {
    top: -5px;
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 980px;
  }

  #overlayContainer {
    top: -9999px;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .overlayImageWrap {
    position: relative;
    width: 980px;
    min-width: 980px;
    max-width: 100vw;
  }

  #selectionTemplates {
    width: 100%;
    z-index: 99;
    cursor: pointer;
  }

  .select-dropdown,
  .select-dropdown * {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }

  .select-dropdown {
    position: relative;
    background-color: #E6E6E6;
    border-radius: 4px;
    width: 200px;
  }

    .select-dropdown select {
      max-width: 100%;
      padding: 8px 24px 8px 4px;
      border: none;
      background-color: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: "proxima-nova", sans-serif;
      font-size: 1rem;
      font-weight: 400;
    }

      .select-dropdown select option {
        background-color: #E6E6E6;
        font-family: "proxima-nova", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        border: none;
      }

      .select-dropdown select:active, .select-dropdown select:focus {
        outline: none;
        box-shadow: none;
      }

    .select-dropdown:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 8px;
      margin-top: -2px;
      border-top: 5px solid #aaa;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
</style>
