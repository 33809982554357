export default {
  pages: {
    contact: {
      header: 'Kontakt',
      body: 'Behöver du komma i kontakt med oss kan du nå oss via telefon eller email enligt nedan.'
    },
    login: {
      header: 'Logga in',
      body: 'Logga in med din e-postadress och lösenordet som har skickats eller beställ ett nytt lösenord.',
      newPasswordRequest: 'Beställ ett nytt lösenord',
      newPasswordReturn: 'Tillbaka',
      newPasswordHeader: 'Nytt lösenord',
      newPasswordBody: 'Klicka på "Beställ ett nytt lösenord" för att få ett nytt lösenord',
      passwordSentHeader: 'Kontrollera din e-post',
      passwordSentBody: 'Vi har skickat ett e-postmeddelande till dig med ditt nya lösenord. Det kan ta några ögonblick innan det tas emot. Kontrollera ditt skräppostfilter om det behövs.\n\nHar du fortfarande problem?',
      passwordSMSHeader: 'Beställ sms',
      passwordSMSBody: 'Ange ditt mobiltelefonnummer för att få ett nytt lösenord via SMS.',
      passwordSendSMSHeader: 'Kontrollera din mobiltelefon',
      passwordSendSMSBody: 'Ett SMS som innehåller ett nytt lösenord har skickats. Det är skickat en SMS med ett nytt lösenord. Det kan ta några ögonblick innan det tas emot.',
      passwordChangeInfo: 'Du har nyligen ändrat ditt lösenord. Du kan ändra ditt lösenord igen på',
      passwordChangeInfoSecond: ''
    },
    orders: {
      orderNumber: 'Order',
      date: 'Datum',
      noOrders: 'Det finns för närvarande inga beställningar'
    },
    gallery: {
      noImagesFound: 'Inga bilder har laddats upp på denna beställning hittills. Bilderna laddas upp automatiskt efter att enheten har returnerats. Du kommer att få ett e-postmeddelande när dina bilder är klara.',
      deviceDisconnected: 'Din enhet är inte online för tillfället.',
      cardTitle: 'Klicka på "Ladda ner album" för att ladda ner bilderna med högsta kvalitet',
      cardTitleMygreetings: 'Klicka på "Ladda ner album" för att ladda ner bilderna med högsta kvalitet och få dina mygreetings-ljudfiler.',
      hideHeader: 'Dold',
      hideBody: "Bilden är nu dold och kommer inte\nvisas för gäster. Det är bara du\nsom kan se bilden.",
      deleteRequestHeader: 'Begäran\nradering.',
      deleteRequestBody: 'Denna bild begärs radering. Bilden kommer att förbli dold tills du antingen tar bort eller ändrar synligheten.',
      slideShowOptionsHeader: 'Bildspelsalternativ',
      images: 'bilder',
      image: 'bild'
    },
    overlay: {
      header: 'Du kan enkelt skapa ett överlägg i form av text, en logotyp eller en grafisk ram – både på skärmen och på bilderna tagna på myselfie.\nEftersom storleken på skärmen är mindre än bilderna tagna på myselfie måste två olika filer laddas upp.\nDet betyder att du själv bestämmer om skärmen ska vara densamma som på bilden',
      screenDesc: 'Här kan du skapa/ladda upp grafiken som ska visas på skärmen (och inte visas på bilden). Denna bildupplösning är ',
      imageDesc: 'Här måste du skapa/ladda upp grafiken som ska synas i bilden (och inte visas på skärmen). Denna bildupplösning är ',
      templateCustom: 'Min design',
      confirmDeleteAll: 'Är du säker på att du vill ta bort allt?'
    }
  },
  components: {
    downloadGallery: {
      infoHeader: 'Ladda ner\nalbum',
      infoBody: 'Ange din e-postadress för att få en\nnedladdningslänk.',
      header: 'Kontrollera din\ne-post',
      body: 'Du kommer inom kort att få en nedladdning\nlänk för dina bilder.',
      headerError: 'WHOOPS!',
      bodyError: 'Ett fel uppstod när dina bilder laddades ned.',
      sendLink: 'Skicka länk'
    },
    mysTextBox: {
      emailLabel: 'E-post',
      passwordLabel: 'Lösenord',
      mobilePhoneLabel: 'Mobiltelefon',
      emailErrorLabel: 'Ogiltig e-post',
      emailEmptyLabel: 'E-post krävs',
      passwordErrorLabel: 'Fel lösenord',
      mobilePhoneErrorLabel: 'Ogiltigt telefonnummer'
    },
    informDeleteRequest: {
      header: 'Begäran har\nskickats',
      body: 'Bilden är nu dold. Hyresgästen till selfie bestämmer\nom bilden ska raderas eller förbli dold.'
    },
    shareGallery: {
      header: 'Alla med den här länken kan se dina bilder'
    }
  },
  menu: {
    overview: 'Mina beställningar',
    contact: 'Kontakt'
  },
  buttons: {
    login: 'Logga in',
    logginIn: 'Loggar in',
    logout: 'Logga ut',
    newPassword: 'Beställ ett nytt lösenord',
    newPasswordSMS: 'Beställ ett nytt lösenord med SMS',
    navToGallery: 'Galleri',
    downloadImages: 'Ladda ner album',
    shareImages: 'Dela album',
    edit: 'Redigera',
    cancelEdit: 'Avbryt',
    sortNewest: 'Nyast',
    sortOldest: 'äldst',
    startSlideShow: 'Starta bildspel',
    playOneSlideShow: 'Spela singeldejt',
    playAllSlideShows: 'Spela alla datum',
    shareOneSlideShow: 'Länk för enstaka datum',
    shareAllSlideShows: 'Länk för alla datum',
    downloadUpperText: 'Ladda ner bild',
    downloadLowerText: '(Högsta kvalitet)',
    deleteDefault: 'Radera',
    deleteMultiple: 'Radera valda',
    deleteRequest: 'Begär radering',
    deleting: 'Deleting',
    hideDefault: 'Göm',
    hidden: 'Visa',
    hiding: 'Göljer',
    hideMultiple: 'Dölj urval',
    showMultiple: 'Visa urval',
    show: 'Visar',
    copyLink: 'Kopiera länk',
    confirm: 'Är du säker?',
    selectAll: 'Markera alla',
    deSelectAll: 'Avmarkera alla',
    deleteAll: 'Radera allt'
  },
  globals: {
    close: 'Stäng',
    linkCopied: 'Länken har kopierats',
    skickar: 'Sänder',
    authFailed: 'Ett fel har uppstått, försök logga in igen',
    apiError: 'Ett fel har inträffat. Se till att din internetanslutning fungerar normalt',
    countDownExpiration: 'Dina bilder kommer automatiskt att raders om',
    days: 'dagar',
    day: 'dag',
    hours: 'timmar',
    hour: 'timme',
    galleryExpired: 'Galleriet har raderats',
    companyPage: 'myselfie.se',
    baseUrl: 'https://myselfie.se',
    phoneNumber: '(0)10 682 00 70',
    infoEmail: 'info@myselfie.se',
    infoPhone: 'Tel: +46 (0)10 682 00 70',
    countryCode: '+46',
    yes: 'Ja',
    no: 'Nej'
  }
}
