<template>
  <div style="text-align:left">
    <div v-if="currentPage === 'Orders'">
      <h1>{{langModule.pages.orders.orderNumber}} #{{order.mys_extordernumber}}</h1>
      <p>{{langModule.pages.orders.date}}: {{formatDate(new Date(order.mys_date))}}</p>
    </div>

    <div v-if="currentPage === 'Gallery'">
      <h1>{{ formatDate(new Date(currentGallery.metadata.index)) }}</h1>
      <p>{{ currentGallery.metadata.imageCount }} {{currentGallery.metadata.imageCount === 1 ? langModule.pages.gallery.image : langModule.pages.gallery.images}}</p>
    </div>

    <div style="padding-top: 11px; width:100%;">
      <p v-if="order.expired && showExpireText" style="font-weight: bold;">{{computedExpireText}}</p>
      <p v-bind:style="{maxWidth: isMobile ? '80%' : '100%'}" v-else-if="order.warnExpire && showExpireText && !isOneDay">{{computedExpireText}}:<strong class="warning">{{computedExpireTextSecond}}</strong></p>
      <p v-if="!order.expired && showExpireText && !order.warnExpire && !isOneDay" v-bind:style="{maxWidth: isMobile ? '80%' : '100%'}">{{computedExpireText}}:<strong>{{computedExpireTextSecond}}</strong></p>

      <p v-if="currentPage === 'Gallery' && !order.expired">{{ includeMygreetings ? langModule.pages.gallery.cardTitleMygreetings : langModule.pages.gallery.cardTitle}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrderInformation',
    props: {
      order: {
        require: true
      },
      currentGallery: {
        default: null
      },
      currentPage: {
        type: String,
        default: 'Orders'
      },
      includeMygreetings: {
        type: Boolean,
        default: false
      },
      isOneDay: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      showExpireText() {
        if (!this.order) return false

        return this.order.expireDay <= 45
      },
      computedExpireText() {
        const expText = this.getExpireText()
        if (!this.order.expired) return expText.split(':')[0]

        return expText
      },
      computedExpireTextSecond() {
        const expText = this.getExpireText()
        if (!this.order.expired) return expText.split(':')[1]

        return expText
      }
    },
    methods: {
      getExpireText() {
        if (!this.order) return ''

        const days = this.order.expireDay
        if (days <= 0) return this.langModule.globals.galleryExpired

        return days === 1
          ? `${this.langModule.globals.countDownExpiration}: ${days} ${this.langModule.globals.day}`
          : `${this.langModule.globals.countDownExpiration}: ${days} ${this.langModule.globals.days}`
      }
    }
  }
</script>

<style scoped>
  strong {
    font-weight: bold;
    background: none;
    position: absolute;
  }

    strong.warning {
      color: #FF6D6A;
      position: absolute;
    }
</style>
