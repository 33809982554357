<template>
  <div class="p-1 p-md-3 my-5 text-center">
    <h1 class="mb-2">{{langModule.pages.contact.header}}</h1>
    <p>{{langModule.pages.contact.body}}</p><br />
    <a :href="hrefPhone">{{langModule.globals.infoPhone}}</a><br />
    <a :href="hrefMail">{{langModule.globals.infoEmail}}</a>

    <div v-if="isDev" class="center-div">
      <MysButton theme="secondary" :text="btnText" @click="toggleDev" :MinWidth="false" style="width:50px;"></MysButton>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'Contact',
    computed: {
      btnText() {
        return this.toggledToProd ? 'PROD' : 'DEV'
      },
      hrefMail() {
        return `mailto:${this.langModule.globals.infoEmail}`
      },
      hrefPhone() {
        const phone = this.langModule.globals.countryCode + this.langModule.globals.phoneNumber
        return `tel:${phone}`
      }
    },
    methods: {
      toggleDev() {
        this.changeToggledToProd(!this.toggledToProd)
        this.changeImages([])
        this.changeLastFile(null)
      }
    }
  }
</script>
<style scoped>
  .center-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:20px;
  }
</style>
