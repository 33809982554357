<template>
  <div id="galleryMainContainer" :class="{'align-items-center d-flex h-100 justify-content-center w-100': initialLoadInProgress || imageCount === 0}" v-bind:style="{ overflowY: anyModal ? 'hidden' : 'visible'}">
    <LoadingDots v-if="initialLoadInProgress" style="position: relative !important;" :size="25"></LoadingDots>
    <div class="headerInfo" v-if="!externalSlide">
      <template v-if="showNoImagesFoundMessage">
        <h4>{{langModule.pages.gallery.noImagesFound}}</h4>
      </template>
      <template v-if="showDeviceDisconnectedMessage">
        <h4>{{langModule.pages.gallery.deviceDisconnected}}</h4>
      </template>
      <template v-if="showInternetErrorMessage">
        <h4>{{langModule.globals.apiError}}</h4>
      </template>
    </div>
    <template>
      <ShareGalleryModal v-if="!externalSlide" :show.sync="showShareModal" :gallery="activeGallery"></ShareGalleryModal>
      <SlidePanelModal :gallery="activeGallery" :show.sync="showSlidePanelModal" :isOneDay="isOneDayGallery"></SlidePanelModal>
      <InformDeleteRequest v-if="!externalSlide" :show.sync="showDeleteInform"></InformDeleteRequest>
      <DownloadGalleryModal v-if="!externalSlide" :show.sync="showDownloadModal" :gallery="activeGallery" :isOneDay="isOneDayGallery"></DownloadGalleryModal>
      <GalleryModal v-if="!initialLoadInProgress && !showSlidePanelModal && !showShareModal && !externalSlide" :show="showGalleryModal" :gallery="activeGallery"></GalleryModal>

      <div v-if="!externalSlide" class="card p-1 p-md-3 my-5" v-for="(gallery, index) in computedImages" :key="index" v-bind:style="{ display: imagesDisplay}">
        <div class="card-header bg-white border-0 user-select-none pointer" @click="toggleGalleryOpen(gallery, index);">
          <div class="d-flex justify-content-between align-items-center">
            <div v-if="showOrderInfo"><OrderInformation :order="currentOrder" currentPage="Gallery" :currentGallery="gallery" :includeMygreetings="mygreetingsAvailable" :isOneDay="isOneDayGallery"></OrderInformation></div>
            <div style="position: absolute; right: 25px; top: 15px;">
              <ChevronMain :direction="gallery.metadata.open ? 'UP' : 'DOWN'"></ChevronMain>
            </div>
          </div>
        </div>
        <div v-if="!orderExpired && gallery.metadata.open" id="cardHolder">
          <div class="card-body">
            <div class="justifyPanelSmall my-5" style="display:grid;">
              <MysButton :text="langModule.buttons.downloadImages" :secondaryText="langModule.buttons.downloadLowerText" @click="setYPos(); activeGallery = getSortedGallery(gallery); changeGalleryDate(getGalleryDate); showDownloadModal = true" theme="secondary" icon="/arrow-to-bottom.svg"></MysButton>
              <MysButton :text="langModule.buttons.startSlideShow" theme="ternary" @click="setYPos(); startSlideshow(gallery)" v-if="!onlyMygreetingsAvailable" icon="/play.svg"></MysButton>
              <MysButton :text="langModule.buttons.shareImages" theme="ternary" @click="setYPos(); showShareDialog(gallery)" icon="/link.svg" v-if="isGalleryOwner"></MysButton>
              <MysButton :text="!gallery.metadata.sortNewestFirst ? langModule.buttons.sortOldest : langModule.buttons.sortNewest" theme="ternary" @click="toggleSortDirection(gallery)" v-if="!onlyMygreetingsAvailable" :icon="!gallery.metadata.sortNewestFirst ? '/icons8-sort_exp_up_210C2C.svg' : '/icons8-sort_exp_down_210C2C.svg'" v-bind:style="{justifyContent: gallery.metadata.editing ? 'right' : 'center' }"></MysButton>
              <MysButton :text="langModule.buttons.cancelEdit" theme="ternary" @click="cancelGalleryEdit(gallery); gallery.metadata.allSelected = false;" v-if="gallery.metadata.editing" icon="/pen.svg"></MysButton>
              <MysButton :text="gallery.metadata.allSelected ? langModule.buttons.deSelectAll : langModule.buttons.selectAll" theme="ternary" @click="selectAllHandler(gallery)" v-if="gallery.metadata.editing" :icon="gallery.metadata.allSelected ? '/icons8-uncheck_all.svg' : '/icons8-check_all.svg'" v-bind:style="{ backgroundColor: '#FFFFFF', border: '1px solid black' }"></MysButton>
              <MysButton :text="getHideText(gallery.metadata.hideModeGal)" v-if="gallery.metadata.editing" theme="ternary" @click="hideBtnClicked(gallery.metadata.index)" :icon="selectedImgIsHidden ? '/icons8-eye_exp_210C2C.svg' : '/icons8-hide_exp_210C2C.svg'" v-bind:style="{ backgroundColor: '#FFFFFF', border: '1px solid black' }"></MysButton>
              <MysButton :text="getDeleteText(gallery.metadata.deleteModeGal)" v-if="gallery.metadata.editing" theme="ternary" @click="deleteImagesForGallery(gallery.metadata.index)" icon="/trashcan.svg" v-bind:style="{ backgroundColor: gallery.metadata.deleteModeGal.isConfirmDelete() ? '#FF6D6A' : '#FFFFFF', border: gallery.metadata.deleteModeGal.isConfirmDelete() ?  'none' : '1px solid black' }"></MysButton>
              <MysButton :text="langModule.buttons.edit" theme="ternary" @click="gallery.metadata.editing = true" v-if="isGalleryOwner && !gallery.metadata.editing && !onlyMygreetingsAvailable" icon="/pen.svg"></MysButton>
            </div>

            <div class="image-grid">
              <template v-for="(image, imageIndex) in sortedGalleryImages(gallery)">
                <a class="image-link" :key="image.name">
                  <div class="thumbParent" @click="setYPos(); openImage($event,gallery, imageIndex, image.name)">
                    <div :data-src=getThumbUrl(image) class="lazy-loaded pointer position-relative image-container" id="imageThumbnail" v-bind:style="{ boxShadow: image.hidden && isGalleryOwner ? 'inset 0 0 0 100vmax rgba(33,12,44, 0.9)' : 'none' }">
                      <span v-if="image.hidden && isGalleryOwner" class="position-absolute p-2 image-selector user-select-none" style="right: 0; width:100%; height: 100%;">
                        <img src="./../assets/icons8-hide_exp.svg" id="iconHide" />
                        <img v-if="image.deleteRequested" src="./../assets/icons8-box_important_exp.svg" id="iconHide2" />
                      </span>

                      <span class="position-absolute p-2 image-selector user-select-none" style="right: 0;" v-if="isGalleryOwner && gallery.metadata.editing">
                        <span class="custom-control custom-checkbox" style="pointer-events:none;">
                          <input type="checkbox" class="custom-control-input" :id="image.name">
                          <label class="custom-control-label" :for="image.name" style="top: -9px; right: -13px; cursor: pointer;"></label>
                        </span>
                      </span>
                    </div>
                  </div>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import apiservice from '@/services/apiservice'
  import { sleep, DeleteMode, HideMode, Chunk } from '@/GeneralMethods'
  let vm = null

  const imageObserver = new IntersectionObserver(async (entries, imgObserver) => {
    if (vm.imageOpened || vm.imageStateChanging || !vm.galleryIsRunning) return

    const arr = []
    let newSize = null

    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const p = () => new Promise(() => {
          const lazyPlaceholder = entry.target
          lazyPlaceholder.style.backgroundImage = `url("${lazyPlaceholder.dataset.src}")`
          if (newSize === null) {
            if (lazyPlaceholder.clientWidth > 0) newSize = `${(lazyPlaceholder.clientWidth / 1.33)}px`
          }

          if (newSize !== null) lazyPlaceholder.style.minHeight = newSize
          else lazyPlaceholder.style.minHeight = `${(lazyPlaceholder.clientWidth / 1.33)}px`

          imgObserver.unobserve(lazyPlaceholder)
        })
        arr.push(p)
      }
    })
    if (arr.length === 0) return

    await Promise.all(arr.map(p => p()))
  })

  export default {
    name: 'Gallery',
    data: () => {
      return {
        activeGallery: null,
        showShareModal: false,
        showSlidePanelModal: false,
        showDownloadModal: false,
        galleryActiveImageName: '',
        initialLoadInProgress: true,
        selectedImages: {},
        mygreetings: [],
        mygreetingsFetched: false,
        galleryIndexToDeleteImagesFrom: null,
        showDeleteImageConfirm: false,
        loadedFirst: false,
        selectedImgIsHidden: false,
        selectedImgIsChosen: false,
        selectAllEvent: null,
        latestFileChosen: false,
        heightChanger: null,
        orderNr: 0,
        fullSlideExternal: false,
        galleryIsRunning: true,
        defaultOrder: {
          mys_extordernumber: 0,
          mys_imageexpiration: '',
          expireDay: 0,
          expired: false
        },
        imageCount: 0,
        internetError: false
      }
    },
    props: {
      Variant: {
        type: String
      },
      UseOnlineParam: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      defaultImageObject() {
        const currentDate = new Date().toISOString().split('T')[0]
        const currentDateTime = new Date().toISOString()

        return {
          metadata: {
            index: currentDate,
            date: currentDateTime,
            editing: false,
            open: true,
            sortNewestFirst: false,
            allSelected: false,
            imageCount: 0
          },
          images: []
        }
      },
      imagesAvailable() {
        return this.imageCount > 0
      },
      mygreetingsAvailable() {
        return this.mygreetings.length > 0
      },
      onlyMygreetingsAvailable() {
        return this.mygreetingsAvailable && !this.imagesAvailable
      },
      deleteBatchUrl() {
        return `/orders/gallery/${this.currentOrderNumber}/batch`
      },
      hideUrl() {
        const orderNr = this.currentOrderNumber
        const hasOrder = orderNr !== null
        return hasOrder ? `/orders/gallery/${orderNr}/hiddenimages` : '/orders/gallery/hiddenimages'
      },
      thumbPrefix() {
        return `${this.apiBaseUrl}/${this.currentShopDomain}/${this.currentOrderNumber}/thumbnail/`
      },
      chunkSize() {
        return this.isDev ? 3 : 10
      },
      anyModal() {
        return this.showShareModal || this.showSlidePanelModal || this.showDeleteInform || this.showDownloadModal || this.showGalleryModal
      },
      computedImages() {
        if (this.initialLoadInProgress) return []

        const imgs = {}
        if (this.onlyMygreetingsAvailable) {
          const defaultObj = this.defaultImageObject
          imgs[defaultObj.metadata.index] = defaultObj
          return imgs
        }

        const indexList = []
        for (const index of Object.keys(this.images)) {
          const metaData = this.images[index].metadata
          if (metaData.imageCount > 0) indexList.push(index)
        }

        for (const newestIndex of indexList.sort((a, b) => a < b ? 1 : 0)) {
          imgs[newestIndex] = this.images[newestIndex]
        }
        return imgs
      },
      online() {
        return this.$route.params.online
      },
      isSpecificGallery() {
        return this.$route.name === 'SpecificGallery'
      },
      isOneDayGallery() {
        return this.$route.name === 'OneDayGallery' && this.$route.params.date !== null
      },
      hasOrderNumber() {
        return 'orderNumber' in this.$route.params
      },
      hasShopDomain() {
        return 'shopDomain' in this.$route.params
      },
      hasDate() {
        return 'date' in this.$route.params
      },
      orderExpired() {
        if (this.currentOrder === null) return true

        return this.currentOrder.expired && !this.isDev
      },
      getOrder() {
        const isAnon = this.externalSlide || this.isOneDayGallery

        if (isAnon) {
          return {
            mys_extordernumber: this.currentOrderNumber,
            mys_imageexpiration: '',
            expireDay: 0,
            expired: false
          }
        }

        if (this.orders.length === 0) return this.defaultOrder

        return this.orders.find(x => x.mys_extordernumber === this.orderNr)
      },
      getImagesUrl() {
        const orderNr = this.currentOrderNumber !== null ? this.currentOrderNumber : this.$route.params.orderNumber
        const shopDomain = this.currentShopDomain
        const lastFileUrl = this.lastFile !== null ? `?lastFile=${this.lastFile}` : ''

        if (this.externalSlide || this.isOneDayGallery) {
          return this.hasDate
            ? `/sharing/images/${shopDomain}/${orderNr}/${this.$route.params.date}${lastFileUrl}`
            : `/sharing/images/${shopDomain}/${orderNr}${lastFileUrl}`
        }

        return `/orders/galleryimages/${orderNr}${lastFileUrl}`
      },
      getMygreetingsUrl() {
        const orderNr = this.currentOrderNumber !== null ? this.currentOrderNumber : this.$route.params.orderNumber
        const shopDomain = this.currentShopDomain
        if (this.isOneDayGallery) {
          return `/orders/gallerymygreetings/${shopDomain}/${orderNr}`
        }
        return `/orders/gallerymygreetings/${orderNr}`
      },
      getGalleryDate() {
        if (this.activeGallery !== null) {
          return new Date(this.activeGallery.metadata.index).toISOString().replace(/T.*/, '').split('-').reverse().join('-')
        } else {
          if (this.hasDate) return this.$route.params.date
        }

        return null
      },
      showInternetErrorMessage() {
        return !this.imagesAvailable && !this.mygreetingsAvailable && this.internetError && !this.initialLoadInProgress
      },
      showNoImagesFoundMessage() {
        return !this.imagesAvailable && !this.mygreetingsAvailable && !this.initialLoadInProgress && !this.showInternetErrorMessage
      },
      showDeviceDisconnectedMessage() {
        return (this.UseOnlineParam && !this.online) && !this.initialLoadInProgress && !this.showInternetErrorMessage
      },
      imagesDisplay() {
        return this.showSlideModal ? 'none' : 'block'
      },
      showOrderInfo() {
        if (this.currentOrder === null) return false

        return this.currentOrder.mys_extordernumber > 0
      }
    },
    watch: {
      anyModal(value) {
        document.body.style.overflowY = value ? 'hidden' : 'visible'
      }
    },
    beforeMount() {
      vm = this
      this.changeGalleryDate(this.getGalleryDate)
      if (this.currentOrder === null) this.changeCurrentOrder(this.defaultOrder)

      if (this.accessToken !== null && !this.isGalleryOwner) this.changeIsGalleryOwner(true)

      if (this.hasOrderNumber) {
        this.orderNr = this.$route.params.orderNumber
        if (this.currentOrderNumber !== this.orderNr) this.changeCurrentOrderNumber(this.orderNr)
      } else {
        this.orderNr = this.currentOrderNumber
      }

      if (this.hasShopDomain) {
        const newShopDomain = this.$route.params.shopDomain
        if (this.currentShopDomain === null) this.changeCurrentShopDomain(newShopDomain)
      } else {
        this.refreshToken()
      }

      this.fullSlideExternal = !this.hasDate
      if (this.heightChanger === null) {
        this.heightChanger = () => {
          this.changeImgChildHeight(window.innerWidth > window.innerHeight ? '94vh' : '94vw')
        }

        screen.orientation.onchange = this.heightChanger
      }
    },
    beforeDestroy() {
      if (this.imageOpened || this.showGalleryModal) {
        const mainContainer = document.getElementById('container')
        if (mainContainer) {
          if (mainContainer.style.overflow !== 'visible') {
            mainContainer.style.overflow = 'visible'
          }
        }
        this.exitImage()
      }

      this.galleryIsRunning = false
    },
    methods: {
      getThumbUrl(img) {
        return `${this.thumbPrefix}${img.name}?${img.thumbnail}`
      },
      async selectAllHandler(gal) {
        const metaData = gal.metadata
        if (!Object.keys(this.selectedImages).includes(metaData.index)) this.$set(this.selectedImages, metaData.index, [])

        metaData.allSelected = !metaData.allSelected
        const imgs = this.sortedGalleryImages(gal)
        let count = 0
        for (const img of imgs) {
          const name = img.name
          this.checkImage(gal, name, metaData.allSelected)
          if (count >= 100) {
            await sleep(1)
            count = 0
          }
          count++
        }
      },
      getSortedGallery(gal) {
        const currentGallery = gal
        currentGallery.images = this.isGalleryOwner ? this.sortedGalleryImages(gal) : this.sortedGalleryImages(gal).filter((obj) => !obj.hidden && !obj.deleteRequested)
        return currentGallery
      },
      toggleGalleryOpen(gallery, index) {
        gallery.metadata.open = !gallery.metadata.open
        gallery.metadata.sortNewestFirst = this.formatDate(new Date(gallery.metadata.index)) === this.formatDate(new Date())
      },
      toggleSortDirection(gallery) {
        gallery.metadata.sortNewestFirst = !gallery.metadata.sortNewestFirst
      },
      showShareDialog(gallery) {
        this.activeGallery = this.getSortedGallery(gallery)
        this.changeGalleryDate(this.getGalleryDate)
        this.showShareModal = true
      },
      async windowResizedHandler() {
        await this.initLazyLoad()
        await this.$nextTick().then(() => this.heightChanger())
      },
      setGridStyle() {
        const w = window.innerWidth
        document.querySelectorAll('.image-grid').forEach(v => {
          v.style.gridTemplateColumns = w < 1000 ? 'repeat(2, minmax(100px, 1fr))' : 'repeat(auto-fill, minmax(300px, 1fr))'
        })
      },
      setImageStyle() {
        document.querySelectorAll('.lazy-loaded').forEach(v => imageObserver.observe(v))
      },
      cancelGalleryEdit(gallery) {
        gallery.metadata.editing = false
        this.$delete(this.selectedImages, gallery.metadata.index)
        gallery.metadata.deleteModeGal = DeleteMode.DeleteMultiple
      },
      async deleteImagesForGallery(gallery) {
        if (!this.selectedImages || !this.selectedImages[gallery]) return
        if (this.imageStateChanging || this.selectedImages[gallery].length === 0) return

        const metaData = this.images[gallery].metadata
        switch (metaData.deleteModeGal) {
          case DeleteMode.DeleteMultiple:
            metaData.deleteModeGal = DeleteMode.ConfirmDelete
            return
          case DeleteMode.ConfirmDelete:
            this.galleryIndexToDeleteImagesFrom = gallery
            await this.deleteBtnClicked()
            metaData.deleteModeGal = DeleteMode.DeleteMultiple
        }
      },
      async deleteBtnClicked() {
        if (this.imageStateChanging || !this.selectedImages) return

        const index = this.galleryIndexToDeleteImagesFrom
        if (!index) return

        const images = this.selectedImages[index]
        if (!images) {
          this.images[index].metadata.deleteModeGal = DeleteMode.DeleteMultiple
          return
        }

        this.images[index].metadata.deleteModeGal = DeleteMode.Deleting
        this.changeImageState(true)
        const chunks = Chunk(images, this.chunkSize)
        const promiseArr = []
        for (let i = 0; i < chunks.length; i++) {
          promiseArr.push(async () => {
            const imageChunk = chunks[i]
            await apiservice.deleteApi(this.deleteBatchUrl, { images: imageChunk })
            for (const image of imageChunk) {
              const imageIndex = this.images[index].images.findIndex(x => x.name === image)
              if (imageIndex > -1) {
                this.images[index].images.splice(imageIndex, 1)
                this.images[index].metadata.imageCount--
                this.imageCount--
              }
            }
          })
        }

        await Promise.all(promiseArr.map(p => p()))

        this.latestFileChosen = false
        this.showDeleteImageConfirm = false
        this.$delete(this.selectedImages, index)
        this.galleryIndexToDeleteImagesFrom = null
        this.changeImageState(false)
      },
      async hideBtnClicked(gallery) {
        if (!this.selectedImages || !this.selectedImages[gallery]) return
        if (this.imageStateChanging || this.selectedImages[gallery].length === 0) return

        this.galleryIndexToDeleteImagesFrom = gallery
        const index = this.galleryIndexToDeleteImagesFrom
        const images = this.selectedImages[index]

        if (!images) return

        this.changeImageState(true)
        this.images[index].metadata.hideModeGal = this.images[index].metadata.hideModeGal === HideMode.ShowMultiple ? HideMode.Showing : HideMode.Hiding

        const chunks = Chunk(images, this.chunkSize)
        const hideImgs = this.images[index].images
        for (let i = 0; i < chunks.length; i++) {
          const promiseArr = []
          for (let j = 0; j < chunks[i].length; j++) {
            const image = chunks[i][j]
            const imageIndex = hideImgs.findIndex(x => x.name === image)

            if (imageIndex > -1) {
              const img = hideImgs[imageIndex]
              promiseArr.push(async () => {
                if (this.selectedImgIsHidden === (img.hidden || img.deleteRequested)) {
                  await apiservice.putApi(this.hideUrl, {
                    imageName: image,
                    hidden: !this.selectedImgIsHidden,
                    deleteRequested: !this.selectedImgIsHidden
                  })

                  img.hidden = !this.selectedImgIsHidden
                  img.deleteRequested = !this.selectedImgIsHidden
                }
              })
            }
          }
          await Promise.all(promiseArr.map(p => p()))
        }

        this.selectedImgIsChosen = false
        this.selectedImgIsHidden = !this.selectedImgIsHidden
        this.changeImageState(false)
        this.images[index].metadata.hideModeGal = this.selectedImgIsHidden ? HideMode.ShowMultiple : HideMode.HideMultiple
      },
      async initLazyLoad() {
        if (this.imageOpened || this.externalSlide) return

        const arr = []
        arr.push(() => this.$nextTick().then(() => this.setGridStyle()))
        arr.push(() => this.$nextTick().then(() => this.setImageStyle()))
        await Promise.all(arr.map(p => p()))
      },
      startSlideshow(gallery) {
        if (gallery !== null) {
          this.activeGallery = this.getSortedGallery(gallery)
          this.changeGalleryDate(this.getGalleryDate)
        }
        const shouldFullscreen = this.showSlideAllGalleries || this.showSlideOneGallery
        this.changeImageOpened(false)
        this.changeShowGalleryModal(true)
        this.showSlidePanelModal = true
        if (!shouldFullscreen) {
          this.changeShowSlideModal(true)
        }
      },
      checkImage(gallery, imageName, manualCheck) {
        const currentCB = document.getElementById(imageName)
        if (!currentCB) return

        currentCB.checked = manualCheck

        if (currentCB.checked) {
          if (!this.selectedImgIsChosen) {
            const img = this.images[gallery.metadata.index].images.find(x => x.name === imageName)
            if (img) {
              this.selectedImgIsHidden = img.hidden || img.deleteRequested
              gallery.metadata.hideModeGal = this.selectedImgIsHidden ? HideMode.ShowMultiple : HideMode.HideMultiple
            }
          }
          this.selectedImages[gallery.metadata.index].push(imageName)
          this.selectedImgIsChosen = true
        } else {
          if (this.selectedImages[gallery.metadata.index].length <= 1) this.selectedImgIsChosen = false

          const index = this.selectedImages[gallery.metadata.index].findIndex(x => x === imageName)
          this.selectedImages[gallery.metadata.index].splice(index, 1)
        }
      },
      openImage(event, gallery, imageIndex, imageName, manualCheck = null) {
        this.changeShowSlideModal(false)

        if (!gallery.metadata.editing) this.changeImageOpened(true)

        event.stopImmediatePropagation()
        if (gallery.metadata.editing) {
          const currentCB = document.getElementById(imageName)
          if (manualCheck === null) currentCB.checked = !currentCB.checked
          else currentCB.checked = manualCheck

          if (!Object.keys(this.selectedImages).includes(gallery.metadata.index)) this.$set(this.selectedImages, gallery.metadata.index, [])

          if (currentCB.checked) {
            if (!this.selectedImgIsChosen) {
              const img = this.images[gallery.metadata.index].images.find(x => x.name === imageName)
              if (img) {
                this.selectedImgIsHidden = img.hidden || img.deleteRequested
                gallery.metadata.hideModeGal = this.selectedImgIsHidden ? HideMode.ShowMultiple : HideMode.HideMultiple
              }
            }

            this.selectedImages[gallery.metadata.index].push(imageName)
            this.selectedImgIsChosen = true
          } else {
            if (this.selectedImages[gallery.metadata.index].length <= 1) this.selectedImgIsChosen = false

            const index = this.selectedImages[gallery.metadata.index].findIndex(x => x === imageName)
            this.selectedImages[gallery.metadata.index].splice(index, 1)
          }
        } else {
          if (this.heightChanger !== null) {
            this.heightChanger()
          }

          const selImg = this.sortedGalleryImages(gallery)[imageIndex]
          this.setSelectedImg(selImg)
          this.activeGallery = this.getSortedGallery(gallery)
          this.changeGalleryDate(this.getGalleryDate)
          this.changeGalleryActiveImageIndex(imageIndex)
          this.galleryActiveImageName = imageName
          this.changeShowGalleryModal(true)
        }
      },
      async fetchImagesByUrl(url) {
        const response = await apiservice.getApi(url)
        const json = await response.json()
        const sortedItems = json.sort((a, b) => {
          const dateTimeA = a.name.substring(a.name.indexOf('_') + 1, a.name.indexOf('+'))
          const dateTimeB = b.name.substring(b.name.indexOf('_') + 1, b.name.indexOf('+'))
          return dateTimeA < dateTimeB ? 1 : -1
        })

        for (let item of sortedItems) {
          const date = new Date(item.timestamp)
          if (date > this.lastDate) this.changeLastDate(date)

          const timeZoneOffsetHour = parseInt(this.getFrom('+', item.timestamp, '0'))

          if (date.getUTCHours() < (8 - timeZoneOffsetHour)) date.setDate(date.getDate() - 1)

          const index = this.getBefore('T', date.toISOString())
          if (this.lastFile === null) {
            this.changeLastFile(item.name)
          } else if (this.latestFileChosen) {
            if (this.lastFile !== item.name) {
              this.changeLastFile(item.name)
              this.latestFileChosen = false
            }
          }

          if (!Object.prototype.hasOwnProperty.call(this.images, index)) {
            const metaObject = {
              metadata: {
                index: index,
                date: date,
                editing: false,
                open: true,
                sortNewestFirst: false,
                allSelected: false,
                imageCount: 0,
                hideModeGal: HideMode.HideMultiple,
                deleteModeGal: DeleteMode.DeleteMultiple
              },
              images: []
            }
            this.setImagesMetadata(index, metaObject)
          }

          if (this.selectedImg !== null) {
            if (this.selectedImg.name === item.name) item = this.selectedImg
          }

          if (this.isGalleryOwner || (!item.hidden && !item.deleteRequested)) {
            this.images[index].images.push(item)
            this.images[index].metadata.imageCount++
            this.imageCount++
          }
        }

        if (this.lastFile !== null && !this.latestFileChosen) {
          if (this.externalSlide) {
            if (!this.fullSlideExternal) {
              this.activeGallery = this.images[this.$route.params.date.replace(/T.*/, '').split('-').reverse().join('-')]
              this.changeGalleryDate(this.getGalleryDate)
            }
          }
          this.latestFileChosen = true
          if (this.firstGalleryFetched) this.changeNewImageAdded(true)
        } else {
          if (!this.galleryIsActive) this.changeNewImageAdded(false)
        }

        this.changeFirstGalleryFetched(true)
      },
      async fetchData() {
        if (this.imageStateChanging || !this.galleryIsRunning) return

        try {
          await this.fetchAnonToken()
          this.refreshToken()
          this.handleExtraWaitTime()
          await this.fetchImagesByUrl(this.getImagesUrl)
          this.internetError = false
          if (this.isDev) {
            document.title = `Images: ${this.imageCount}`
          }
        } catch (err) {
          if (err instanceof TypeError) this.internetError = true
          console.log(err)
        }
      },
      async fetchMygreetings() {
        if (this.externalSlide) return
        try {
          if (!this.mygreetingsFetched) {
            this.mygreetingsFetched = true
            const mygreetingsResponse = await apiservice.getApi(this.getMygreetingsUrl)
            this.mygreetings = await mygreetingsResponse.json()
          }
        } catch (err) {
          console.log(err)
        }
      },
      async fetchDelay() {
        if (!this.galleryIsRunning) return

        const waitTime = 10000
        const extraTime = this.extraWaitTime
        await sleep(waitTime + extraTime)
      },
      async initFirstGallery() {
        await this.fetchData()
        await this.fetchMygreetings()
      },
      async fetchAnonToken() {
        const isAnon = this.externalSlide || this.isOneDayGallery
        if (!isAnon) return

        this.handleExpiration()

        let anonExpired = true
        if (this.jwtOrderObject !== null) {
          anonExpired = Date.now() >= this.loginExpire
        }

        if (anonExpired) {
          const tokenBody =
          {
            shopDomain: this.$route.params.shopDomain,
            orderNumber: this.$route.params.orderNumber,
            date: this.$route.params.date,
            connectionState: '1',
            manualChecksum: this.$route.params.checksum
          }
          const anonResponse = await apiservice.postApiAuth('/token/ChecksumJWT', tokenBody)
          const anonJson = await anonResponse.json()
          this.handleAnonResponseResult(anonJson)
        }
      }
    },
    async updated() {
      if (!this.galleryIsRunning || this.imageOpened || this.imageStateChanging) return

      await this.initLazyLoad()
    },
    async mounted() {
      await this.fetchAnonToken()
      if (this.orders.length === 0 && !this.isOneDayGallery && !this.externalSlide) await this.fetchOrders()

      this.changeCurrentOrder(this.getOrder)
      this.registerNewOrder(this.currentOrder)
      this.imageCount = this.getImageCount()

      if (!this.imagesAvailable) await this.initFirstGallery()

      this.initialLoadInProgress = false
      window.onresize = this.windowResizedHandler

      if (this.externalSlide) {
        this.startSlideshow(this.activeGallery)
        this.showSlide(!this.fullSlideExternal)
      }

      await this.initLazyLoad()
      await this.fetchDelay()
      if (this.currentOrder === null && this.galleryIsRunning) this.changeCurrentOrder(this.getOrder)

      if (this.currentOrder === null) this.changeCurrentOrder(this.defaultOrder)

      let lastImageCount = this.imageCount
      if (this.currentOrder !== null) {
        const expiredGallery = this.currentOrder.expired && !this.isDev
        while (this.galleryIsRunning && !expiredGallery) {
          await this.fetchData()
          if (lastImageCount !== this.imageCount) {
            this.changeNewImagesAvailable(true)
            lastImageCount = this.imageCount
          }
          await this.fetchDelay()
        }
      }
    }
  }
</script>
<style>
  body, html {
    height: 100%;
  }

  .headerInfo {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .headerInfo h4 {
      max-width: 550px;
      min-width: 320px;
      white-space: normal;
    }

  #cardHolder {
    margin-top: -60px;
    overflow: hidden;
    transition: height 200ms;
  }

  .image-container {
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }

  .thumbParent {
    position: relative;
    top: 0;
    left: 0;
  }

  #iconHide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
  }

  #iconHide2 {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 14px;
    height: 14px;
  }

  #imageThumbnail {
    position: relative;
    top: 0;
    left: 0;
  }

  .justifyPanelSmall {
    justify-content: space-between !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    grid-gap: 20%;
    margin-bottom: auto;
    row-gap: 20px;
    padding-bottom: 20px;
  }

  #progress {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: transparent linear-gradient(205deg,#401955,#250e32 41%,#220d2d 100%,#4f345f 0) 0 0 no-repeat padding-box;
    height: 5px;
    z-index: 100;
  }

  .SelectedImgButton {
    border-radius: 50rem;
    width: 250px;
    height: 50px;
    text-align: center;
    white-space: break-spaces;
    line-height: 1;
  }

    .SelectedImgButton span {
      display: block;
      line-height: 1;
      text-align: center;
    }

    .SelectedImgButton img {
      height: 12px;
      width: 12px;
      position: relative;
    }

  .iconStyle {
    width: 20px;
    height: 40px;
    float: right;
    padding-right: 5px;
  }

  .deleteHeader {
    font-family: "proxima-nova", sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: white;
    width: 171px;
    position: absolute;
    top: 35px;
    right: -81px;
    line-height: 49px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .hideBody {
    font-family: "proxima-nova", sans-serif;
    color: white;
    font-size: 13px;
    font-weight: 400;
    width: 242px;
    position: absolute;
    right: -154px;
    top: 80px;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .deleteBody {
    font-family: "proxima-nova", sans-serif;
    color: white;
    font-size: 13px;
    font-weight: 400;
    width: 242px;
    position: absolute;
    right: -154px;
    top: 130px;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  #coverColorDiv {
    box-shadow: inset 0 0 0 100vmax rgba(33,12,44, 0.9);
    top: 0;
    height: 100%;
    position: absolute;
  }

  #dl_topBtnText {
    position: relative;
    top: 8px;
    left: 58px;
    padding: 1px;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  #dl_lowBtnText {
    position: relative;
    top: 8px;
    left: 58px;
    padding-top: 5px;
    font-size: 10px;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .btn_icons {
    position: absolute;
    top: 14px;
    left: 211px;
  }

  .normal_BtnText {
    position: relative;
    top: 16px;
    left: 45%;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .normal_BtnTextClicked {
    position: relative;
    top: 16px;
    left: 83px;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  #wrap {
    margin: 0 auto;
  }

  #outer {
    position: relative;
    overflow: hidden;
  }

    #outer:before {
      bottom: 0;
    }

  #contentDiv {
    bottom: 0;
  }

  #imageParent {
    position: relative;
    margin-top: 30px;
    height: 70vh;
  }

  #coverImg {
    display: block;
    max-width: 101vh;
    width: 100%;
    max-height: 80vh;
    height: 100%;
    box-shadow: inset 0 0 0 100vmax rgba(33,12,44, 0.9);
    object-fit: contain;
  }

  #coverText {
    display: none;
    position: absolute;
    width: 100%;
    padding-top: 215px;
    right: 45%;
  }

  .ImgClassCover {
    width: 100vw;
    box-shadow: inset 0 0 0 100vmax rgba(33,12,44, 0.9);
    background-position-y: 30px;
    background-position-x: center;
    background-size: contain;
    height: 100%;
    background-repeat: no-repeat;
  }

  #backgroundImgContainer {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100%;
    align-items: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    background-size: contain;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .slow-fade-enter-active {
    animation: slow-fade-in 0.4s;
  }

  .slow-fade-leave-active {
    animation: slow-fade-in 0.4s reverse;
  }

  @keyframes slow-fade-in {
    0% {
      opacity: 0;
    }

    25% {
      opacity: .25;
    }

    50% {
      opacity: .50;
    }

    75% {
      opacity: .75;
    }

    100% {
      opacity: 1;
    }
  }
</style>
