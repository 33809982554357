<template>
  <transition name="fade">
    <div class="modal position-fixed" v-if="show" id="modalContent">
      <div v-if="showHeader" class="modal-header" id="modalHeader" style="width:40px; height: 40px; top: 5px; cursor: pointer;" @click="dismiss">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="20" height="20" class="pointer" style="float:right;">
          <path :fill="dissmissClass"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.586 2.586a2 2 0 012.828 0L32 29.172 58.586 2.586a2 2 0 112.828 2.828L34.828 32l26.586 26.586a2 2 0 11-2.828 2.828L32 34.828 5.414 61.414a2 2 0 11-2.828-2.828L29.172 32 2.586 5.414a2 2 0 010-2.828z" />
        </svg>
      </div>
      <div class="modal-content w-100">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import store from '@/store'
let vm = null

const imgObserver = new ResizeObserver(async imgEntries => {
  const backgroundImgContainer = imgEntries[0].target
  await vm.$nextTick().then(async () => await vm.adjustCover(backgroundImgContainer))
})

const resizeObserver = new IntersectionObserver(entries => {
  if (store.getters.galleryIsActive) return

  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const container = entry.target
      if (container.id === 'backgroundImgContainer') imgObserver.observe(container)
    }
  })
})

export default {
  name: 'Modal',
  props: {
    gallery: {
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dissmissClass () {
      return this.dark ? '#fff' : '#000'
    },
    showContent() {
      return this.show && this.showLogo
    }
  },
  methods: {
    dismiss () {
      this.$emit('dismiss')
      this.$emit('update:show', false)
      this.exitModal()

      this.$nextTick(() => {
        this.hideLayoutScroll(false)
        document.scrollingElement.scrollTo(0, this.currentYPos)
      })
    },
    closeModal() {
      const modalHeader = document.getElementById('modalHeader')
      if (modalHeader) modalHeader.click()

      const mobileCross = document.getElementById('mobileCross')
      if (mobileCross) mobileCross.click()
    },
    setKeyEvent () {
      if (this.keypressEventMethod !== null) return

      this.keypressEventMethod = e => {
        if (e.key === 'Escape') {
          this.closeModal()
          return
        }

        if (this.showSlideModal || this.galleryIsActive) return

        if (this.gallery !== null) {
          switch (e.key) {
            case 'ArrowRight':
              this.On_SlideRight()
              break
            case 'ArrowLeft':
              this.On_SlideLeft()
              break
            case 'Enter':
              this.On_SlideRandom()
              break
          }
        }
      }
      document.onkeydown = this.keypressEventMethod
    }
  },
  beforeMount () {
    vm = this
    this.setKeyEvent()
  },
  updated () {
    if (this.slideShutdown) {
      this.changeSlideShutdown(false)
      this.closeModal()
    } else {
      if (this.selectedImg) {
        const backgroundContainer = document.getElementById('backgroundImgContainer')

        if (backgroundContainer) {
          if (this.show) resizeObserver.observe(backgroundContainer)
          else resizeObserver.unobserve(backgroundContainer)
        }
      }
    }
  }
}
</script>
