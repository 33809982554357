<template>
  <div v-once class="spinner position-absolute">
    <div class="bounce1 mr-2" :style="{width: size + 'px', height: size + 'px'}"></div>
    <div class="bounce2 mr-2" :style="{width: size + 'px', height: size + 'px'}"></div>
    <div class="bounce3 mr-2" :style="{width: size + 'px', height: size + 'px'}"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingDots',
  props: {
    size: {
      required: false,
      type: Number,
      default: 10
    }
  }
}
</script>

<style scoped>
.spinner {
  width: 100%;
  text-align: center;
}

.spinner > div {
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
      transform: scale(1.0);
    }
}
</style>
