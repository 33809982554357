<template>
  <div class=" d-flex flex-grow-1 flex-row" id="layoutContainer" style="overflow-x: visible;">
    <Menu :show="showMenu"></Menu>
    <div id="container" class="container-fluid m-0 p-0 d-flex">
      <div class="d-flex flex-grow-1 px-2 px-md-5 flex-column">
        <MobileHeader></MobileHeader>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  data: function () {
    return {
      authenticationFailed: false,
      isAuthenticated: true,
      showMenu: false
    }
  },
  methods: {
    switchPage (page) {
      this.activePage = page
      this.hideMenuFn()
    },
    showMenuFn () {
      this.showMenu = true
    },
    hideMenuFn () {
      this.showMenu = false
    }
  }
}
</script>

<style scoped>
</style>
