export function randomNumber (min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function validateEmail (email) {
  /* eslint-disable */
  return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)
}

export function validatePhone (phone) {
  let phoneCheck = phone.replace(/ /g, '')
  if (phoneCheck.length < 10) return false

  phoneCheck = phoneCheck.replace('+', '')

  return /^\d+$/.test(phoneCheck) && phoneCheck.length === 10
}

export function getBackgroundSize (elem) {
  const computedStyle = getComputedStyle(elem)
  const image = new Image()
  const src = computedStyle.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2')
  let cssSize = computedStyle.backgroundSize
  const elemW = parseInt(computedStyle.width.replace('px', ''), 10)
  const elemH = parseInt(computedStyle.height.replace('px', ''), 10)
  const elemDim = [elemW, elemH]
  const computedDim = []
  let ratio

  image.src = src
  ratio = image.width > image.height ? image.width / image.height : image.height / image.width
  cssSize = cssSize.split(' ')
  computedDim[0] = cssSize[0]
  computedDim[1] = cssSize.length > 1 ? cssSize[1] : 'auto'
  if (cssSize[0] === 'cover') {
    if (elemDim[0] > elemDim[1]) {
      if (elemDim[0] / elemDim[1] >= ratio) {
        computedDim[0] = elemDim[0]
        computedDim[1] = 'auto'
      } else {
        computedDim[0] = 'auto'
        computedDim[1] = elemDim[1]
      }
    } else {
      computedDim[0] = 'auto'
      computedDim[1] = elemDim[1]
    }
  } else if (cssSize[0] === 'contain') {
    if (elemDim[0] < elemDim[1]) {
      computedDim[0] = elemDim[0]
      computedDim[1] = 'auto'
    } else {
      if (elemDim[0] / elemDim[1] >= ratio) {
        computedDim[0] = 'auto'
        computedDim[1] = elemDim[1]
      } else {
        computedDim[1] = 'auto'
        computedDim[0] = elemDim[0]
      }
    }
  } else {
    for (let i = cssSize.length; i--;) {
      if (cssSize[i].indexOf('px') > -1) {
        computedDim[i] = cssSize[i].replace('px', '')
      } else if (cssSize[i].indexOf('%') > -1) {
        computedDim[i] = elemDim[i] * (cssSize[i].replace('%', '') / 100)
      }
    }
  }
  if (computedDim[0] === 'auto' && computedDim[1] === 'auto') {
    computedDim[0] = image.width
    computedDim[1] = image.height
  } else {
    ratio = computedDim[0] === 'auto' ? image.height / computedDim[1] : image.width / computedDim[0]
    computedDim[0] = computedDim[0] === 'auto' ? image.width / ratio : computedDim[0]
    computedDim[1] = computedDim[1] === 'auto' ? image.height / ratio : computedDim[1]
  }
  return {
    width: computedDim[0],
    height: computedDim[1]
  }
}

export function uuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export function Chunk (sourceArray, size) {
  const jaggedArray = []
  const len = sourceArray.length
  for (let i = 0; i < len; i += size) {
    jaggedArray.push(sourceArray.slice(i, i + size))
  }
  return jaggedArray
}

export function createKeyEvent(type, code, keyCode, useBubbles = true) {
  return new KeyboardEvent(type, {
    code: code,
    key: code,
    charCode: keyCode,
    keyCode: keyCode,
    view: window,
    bubbles: useBubbles
  })
}

export function prettifyJson(json) {
  return JSON.stringify(JSON.parse(json), null, 2)
}

export function getExpireDay(dateString) {
  const now = Date.now()
  const expireDate = new Date(dateString)

  let secondsLeft = Math.floor((expireDate - (now)) / 1000)
  let minutesLeft = Math.floor(secondsLeft / 60)
  let hoursLeft = Math.floor(minutesLeft / 60)
  let daysLeft = Math.floor(hoursLeft / 24)

  hoursLeft = hoursLeft - (daysLeft * 24)
  minutesLeft = minutesLeft - (daysLeft * 24 * 60) - (hoursLeft * 60)
  secondsLeft = secondsLeft - (daysLeft * 24 * 60 * 60) - (hoursLeft * 60 * 60) - (minutesLeft * 60)
  if (hoursLeft > 0 || minutesLeft > 0 || secondsLeft > 0) daysLeft++

  return daysLeft
}

export class Stopwatch {
  #startTime;

  constructor () {
    this.#startTime = performance.now()
  }

  get milliseconds () {
    return performance.now() - this.#startTime
  }
}

export class DeleteMode {
  static Delete = new DeleteMode('delete')
  static Deleting = new DeleteMode('deleting')
  static DeleteMultiple = new DeleteMode('deleteMultiple')
  static RequestDelete = new DeleteMode('requestDelete')
  static ConfirmDelete = new DeleteMode('confirmDelete')

  constructor (name) {
    this.name = name
  }

  isDelete () {
    return this === DeleteMode.Delete
  }

  isDeleteMultiple () {
    return this === DeleteMode.DeleteMultiple
  }

  isRequestDelete () {
    return this === DeleteMode.RequestDelete
  }

  isConfirmDelete () {
    return this === DeleteMode.ConfirmDelete
  }
}

export class HideMode {
  static Hide = new HideMode('hide')
  static Hidden = new HideMode('hidden')
  static Hiding = new HideMode('hiding')
  static Showing = new HideMode('showing')
  static HideMultiple = new HideMode('hideMultiple')
  static ShowMultiple = new HideMode('showMultiple')
  static RequestHide = new HideMode('requestHide')
  static ConfirmHide = new HideMode('confirmHide')

  constructor (name) {
    this.name = name
  }

  isHide () {
    return this === HideMode.Hide
  }

  isHidden () {
    return this === HideMode.Hidden
  }

  isHideMultiple () {
    return this === HideMode.HideMultiple
  }

  isRequestHide () {
    return this === HideMode.RequestHide
  }

  isConfirmHide () {
    return this === HideMode.ConfirmHide
  }
}
