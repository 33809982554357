<template>
  <div id="loginContainerWrap">
    <div class="modal-content" id="loginContainer">
      <div class="w-md-50 w-xl-30 d-flex justify-content-center align-items-center flex-column">
        <a :href="langModule.globals.baseUrl" style="top: -70px; position: absolute; width: 410px; max-width: 76%;"><img alt="" src="./../assets/myselfie_logo_gray.svg" class="mx-xl-auto"></a>
        <div id="flexDlContainer" class="d-flex flex-column" style="position: absolute; top: 104px; max-width: 76%; width:420px; ">
          <div style="position: relative;">
            <div style="display: inline-block; padding-bottom: 10px;">
              <h1 style="font-family: proxima-nova, sans-serif; font-weight: 700; padding-bottom: 15px; color:#3C3C3C;">{{getHeader}}</h1>
              <p>{{getBody}}</p>
            </div>
          </div>
          <div class="d-flex flex-column w-100 mt-2">
            <div v-if="!newPasswordSent && !newPasswordSmsSent && !newPasswordSms" style="justify-content: center; display: flex;">
              <MysTextBox :isDisabled="tbDisabled" v-model="username" :eventMethod="KeyUpLogin" :errLabelText="getErrorLabelText" :label="langModule.components.mysTextBox.emailLabel" type="email" :autoFocus="shouldAutoFocus" id="txtEmail"></MysTextBox>
            </div>
            <div v-if="!newPassword && !newPasswordSms && !newPasswordSent && !newPasswordSmsSent" class="mt-3" style="justify-content: center; display: flex;">
              <MysTextBox :isDisabled="tbDisabled" type="password" v-model="password" :eventMethod="KeyUpLogin" :errLabelText="loginSuccess ? '' : langModule.components.mysTextBox.passwordErrorLabel" :label="langModule.components.mysTextBox.passwordLabel" :isPassword="true" id="pwTb"></MysTextBox>
            </div>

            <div v-if="newPasswordSms && !newPasswordSmsSent" class="mt-3" style="justify-content: center; display: flex;">
              <MysTextBox :isDisabled="tbDisabled" type="tel" v-model="phone" :eventMethod="KeyUpSMS" :errLabelText="phoneValid ? '' : langModule.components.mysTextBox.mobilePhoneErrorLabel" :label="langModule.components.mysTextBox.mobilePhoneLabel" :autoFocus="shouldAutoFocus" id="phoneTb"></MysTextBox>
            </div>

            <div v-if="!newPasswordSms && !newPasswordSmsSent && !newPasswordSent" class="d-flex flex-column w-100" style="padding-top: 15px; justify-content: center; display: flex;">
              <MysButton theme="secondary" :text="getBtn" @click="login" type="button" :disabled="btnDisabled"></MysButton>
            </div>
            <div v-if="(newPasswordSent && !newPasswordSmsSent) || newPasswordSms" class="d-flex flex-column w-100" style="padding-top: 15px; justify-content: center; display: flex;">
              <MysButton theme="secondary" id="smsBtn" :text="getNewPasswordText" @click="sendSMS" type="button" :isDisabled="btnDisabled"></MysButton>
            </div>
          </div>
          <div style="text-align:center; padding-top:20px; padding-bottom: 16px;">
            <p id="pwNav" @click="getNewPassword" style="justify-content: center; display: flex; cursor: pointer;">{{getNavLabelText}}</p>
            <p v-if="getPasswordChangeInfoText.length > 0" style="padding-top: 15px; color: red; justify-content: center; display: flex; text-align: left;">{{getPasswordChangeInfoText}}</p>
          </div>
          <LangPicker></LangPicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import apiservice from '@/services/apiservice'
  export default {
    name: 'Login',
    data() {
      return {
        username: '',
        password: '',
        phone: '',
        loginSuccess: true,
        isLoggingIn: false,
        loggedIn: false,
        newPassword: false,
        newPasswordSent: false,
        newPasswordSms: false,
        newPasswordSmsSent: false,
        phoneValid: true,
        emailValid: true,
        shouldAutoFocus: true,
        sending: false
      }
    },
    computed: {
      tbDisabled() {
        return this.logginIn || this.loggedIn
      },
      btnDisabled() {
        return this.isLoggingIn || this.loggedIn || this.sending
      },
      getNavLabelText() {
        if (this.newPassword || this.newPasswordSms || this.newPasswordSent || this.newPasswordSmsSent) return this.langModule.pages.login.newPasswordReturn

        return this.langModule.pages.login.newPasswordRequest
      },
      getNewPasswordText() {
        return this.sending ? this.langModule.globals.sending : this.langModule.buttons.newPasswordSMS
      },
      getErrorLabelText() {
        if (!this.emailValid || !this.loginSuccess) return this.langModule.components.mysTextBox.emailErrorLabel

        return ''
      },
      getHeader() {
        if (this.newPassword) return this.langModule.pages.login.newPasswordHeader

        if (this.newPasswordSms) return this.langModule.pages.login.passwordSMSHeader

        if (this.newPasswordSent) return this.langModule.pages.login.passwordSentHeader

        if (this.newPasswordSmsSent) return this.langModule.pages.login.passwordSendSMSHeader

        return this.langModule.pages.login.header
      },
      getBody() {
        if (this.newPassword) return this.langModule.pages.login.newPasswordBody

        if (this.newPasswordSms) return this.langModule.pages.login.passwordSMSBody

        if (this.newPasswordSent) return this.langModule.pages.login.passwordSentBody

        if (this.newPasswordSmsSent) return this.langModule.pages.login.passwordSendSMSBody

        return this.langModule.pages.login.body
      },
      getBtn() {
        if (this.sending) return this.langModule.globals.sending

        if (this.newPasswordSent || this.newPasswordSmsSent) return this.langModule.buttons.login

        if (this.isLoggingIn) return this.langModule.buttons.logginIn

        if (this.newPassword) return this.langModule.buttons.newPassword

        return this.langModule.buttons.login
      },
      getPasswordChangeInfoText() {
        if (this.newPasswordSent || this.newPasswordSmsSent || !this.newPassword) return ''
        if (!this.emailIsValid(this.username)) return ''

        const passwordChangeDate = this.handlePasswordRetryDate(this.username)
        if (!passwordChangeDate) return ''

        const currentDate = new Date()
        const prevShortDate = this.shortDate(passwordChangeDate)
        const currentShortDate = this.shortDate(currentDate)

        if (currentDate > passwordChangeDate || prevShortDate === currentShortDate) return ''

        const localeText = this.langModule.pages.login.passwordChangeInfo
        const localeTextSecond = this.langModule.pages.login.passwordChangeInfoSecond === '' ? '' : ` ${this.langModule.pages.login.passwordChangeInfoSecond}`
        return `${localeText} ${prevShortDate}${localeTextSecond}`
      }
    },
    beforeMount() {
      this.shouldAutoFocus = !this.isMobile
    },
    methods: {
      async login() {
        if (this.sending) return

        if (!this.emailIsValid(this.username)) {
          this.loginSuccess = false
          return
        }

        if (this.newPassword) {
          if (this.getPasswordChangeInfoText.length > 0) return

          this.sending = true
          const resetByEmailResponse = await apiservice.postApiAuth('/user/password', { Email: this.username })
          this.sending = false
          if (resetByEmailResponse.status !== 200) return

          this.setNewPasswordSent()
          this.handlePasswordRetryDate(this.username, this.addMinutes(new Date(), 15))
          return
        }

        if (this.isLoggingIn) return

        this.isLoggingIn = true

        const loginData = { username: this.username, password: this.password }
        const response = await apiservice.postApiAuth('/gallerytoken', { Email: loginData.username, Password: loginData.password })
        this.loginSuccess = response.status === 200
        this.isLoggingIn = false
        if (this.loginSuccess) {
          const { token, orders } = await response.json()
          this.sortOrders(orders)
          loginData.token = token
          this.registerLogin(loginData)
          this.registerDefaultLanguage()
          this.loggedIn = true
          const redir = this.$route.query.redir
          if (redir === undefined) this.$router.replace({ name: 'OrdersView' })
          else this.$router.push(redir)
        }
      },
      async KeyUpLogin(event) {
        if (event.key === 'Enter') await this.login()
      },
      KeyUpSMS(event) {
        if (event.key === 'Enter') {
          const btn = document.getElementById('smsBtn')
          if (btn) btn.click()
        }
      },
      getNewPassword() {
        if (this.newPasswordSent || this.newPasswordSmsSent) {
          this.resetBooleans()
          return
        }

        this.emailValid = true

        if (this.newPasswordSms) {
          this.resetBooleans()
          return
        }

        this.newPassword = !this.newPassword
      },
      async sendSMS() {
        if (this.sending) return

        if (!this.newPasswordSms && !this.newPasswordSmsSent) {
          this.setNewPasswordSms()
          return
        }

        if (this.newPasswordSms) {
          this.phoneValid = this.phoneIsValid(this.phone.startsWith('+') ? this.phone : this.langModule.globals.countryCode + this.phone)
          if (this.phoneValid) {
            this.sending = true
            const resetBySmsResponse = await apiservice.postApiAuth('/user/password', { Email: this.username, Phone: this.phone.startsWith('+') ? this.phone.slice(3) : this.phone })
            this.sending = false
            if (resetBySmsResponse.status !== 200) return

            this.setNewPasswordSmsSent()
          }
        }
      },
      resetBooleans() {
        this.newPasswordSmsSent = false
        this.newPassword = false
        this.newPasswordSms = false
        this.newPasswordSent = false
        this.loginSuccess = true
        this.phoneValid = true
        this.emailValid = true
        this.sending = false
      },
      setNewPasswordSmsSent() {
        this.newPasswordSmsSent = true
        this.newPassword = false
        this.newPasswordSms = false
        this.newPasswordSent = false
      },
      setNewPassword() {
        this.newPasswordSmsSent = false
        this.newPassword = true
        this.newPasswordSms = false
        this.newPasswordSent = false
      },
      setNewPasswordSms() {
        this.newPasswordSmsSent = false
        this.newPassword = false
        this.newPasswordSms = true
        this.newPasswordSent = false
      },
      setNewPasswordSent() {
        this.newPasswordSmsSent = false
        this.newPassword = false
        this.newPasswordSms = false
        this.newPasswordSent = true
      }
    }
  }
</script>

<style scoped>
  #loginContainerWrap{
    display: flex;
    min-width: 100%;
    min-height: 100%;
    overflow-y: hidden;
  }

  #loginContainer {
    height: 100vmax;
    width: 100vmax;
    min-width: 100%;
    min-height: 100%;
    background: #62D3AF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #loginEmailWrapper {
    text-align: left;
    position: relative;
  }

  h2 {
    font: 50px Arial !important;
    font-weight: bold !important;
  }

  p {
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    color: #3A3A3A;
    font-size: 18px;
    text-align: left;
  }
</style>
