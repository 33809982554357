<template>
  <span>
    <MysButton :text="btnText" theme="ternary" @click="copy()" :icon="getIcon"></MysButton>
    <input style="display: none;" class="form-control" name="copyValue" v-model="copyValue" readonly disabled>
  </span>
</template>

<script>
  export default {
    name: 'MysCopyButton',
    data: () => {
      return {
        copied: false
      }
    },
    props: {
      text: {
        type: String,
        require: true
      },
      copyValue: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'link'
      }
    },
    computed: {
      btnText() {
        if (!this.copied) return this.text

        switch (this.type) {
          case 'link': return this.langModule.globals.linkCopied
          default: return this.text
        }
      },
      getIcon() {
        return this.copied ? '/check-success.svg' : '/link.svg'
      }
    },
    methods: {
      copy() {
        if (this.copyValue === '') return

        this.$copyText(this.copyValue).then(() => {
          this.copied = true
        }).catch(err => {
          this.copied = false
          console.log(err)
        })
      }
    }
  }
</script>
