export default {
  pages: {
    contact: {
      header: 'Contact',
      body: 'If you need to get in touch with us, you can contact us with the information below.'
    },
    login: {
      header: 'Log in',
      body: 'Log in with your email and the password that has been sent or order a new password.',
      newPasswordRequest: 'Order a new password',
      newPasswordReturn: 'Back',
      newPasswordHeader: 'New Password',
      newPasswordBody: 'Click on "Order a new password" to receive a new password',
      passwordSentHeader: 'Check your email',
      passwordSentBody: 'We have sent you an email containing your new password. It may take a few moments before it is received. Check your spam filter if necessary.\n\nStill having problems?',
      passwordSMSHeader: 'Order sms',
      passwordSMSBody: 'Enter your mobile telephone number to receive a new password by SMS.',
      passwordSendSMSHeader: 'Check your mobile telephone',
      passwordSendSMSBody: 'An SMS containing a new password has been sent. Der er sendt en SMS med et nyt password. It may take a few moments before it is received.',
      passwordChangeInfo: 'You have recently changed your password. You can change your password again at',
      passwordChangeInfoSecond: ''
    },
    orders: {
      orderNumber: 'Order',
      date: 'Date',
      noOrders: 'There are currently no orders'
    },
    gallery: {
      noImagesFound: 'No images have been uploaded on this order so far. The images are automatically uploaded after the device has been returned. You will receive an email when your images are ready.',
      deviceDisconnected: 'Your device is not online at the moment.',
      cardTitle: 'Click on "Download album" to download the images with highest quality',
      cardTitleMygreetings: 'Click on "Download album" to download the images with highest quality and get your mygreetings audiofiles',
      hideHeader: 'Hidden',
      hideBody: "The image is now hidden and will not\nbe shown for guests. It's only you\nThat can see the image.",
      deleteRequestHeader: 'Request\ndeletion.',
      deleteRequestBody: 'This image is delete requested. The image will remain hidden until you either delete or change the visibility.',
      slideShowOptionsHeader: 'Slideshow options',
      images: 'images',
      image: 'image'
    },
    overlay: {
      header: 'You can easily create an overlay in the form of text, a logo or a graphic frame - both on the screen and on the pictures taken on myselfie.\nSince the size of the screen is smaller than the pictures taken on myselfie, two different files must be uploaded.\nThat means you decide for yourself whether the screen should be the same as in the picture',
      screenDesc: 'Here you can create/upload the graphics to be displayed on the screen (and not appear in the picture). This image resolution is ',
      imageDesc: 'Here you must create/upload the graphics that must appear in the picture (and not appear on the screen). This image resolution is ',
      templateCustom: 'My design',
      confirmDeleteAll: 'Are you sure you want to delete everything?'
    }
  },
  components: {
    downloadGallery: {
      infoHeader: 'Download\nalbum',
      infoBody: 'Enter your email in order to get a\ndownload link.',
      header: 'Check your\nemail',
      body: 'Your will shortly receive a download\nlink for your images.',
      headerError: 'WHOOPS!',
      bodyError: 'An error occurred while downloading your images.',
      sendLink: 'Send link'
    },
    mysTextBox: {
      emailLabel: 'Email',
      passwordLabel: 'Password',
      mobilePhoneLabel: 'Mobile phone',
      emailErrorLabel: 'Invalid email',
      emailEmptyLabel: 'Email is required',
      passwordErrorLabel: 'Wrong password',
      mobilePhoneErrorLabel: 'Invalid phone number'
    },
    informDeleteRequest: {
      header: 'Request has\nbeen sendt',
      body: 'The image is now hidden. The tenant of the myselfie decides\nif the image should be deleted or remain hidden.'
    },
    shareGallery: {
      header: 'Everyone with this link are able to view your images'
    }
  },
  menu: {
    overview: 'My orders',
    contact: 'Contact'
  },
  buttons: {
    login: 'Log in',
    logginIn: 'Logging in',
    logout: 'Log out',
    newPassword: 'Order a new password',
    newPasswordSMS: 'Order a new password with SMS',
    navToGallery: 'Gallery',
    downloadImages: 'Download album',
    shareImages: 'Share album',
    edit: 'Edit',
    cancelEdit: 'Cancel',
    sortNewest: 'Newest',
    sortOldest: 'Oldest',
    startSlideShow: 'Start slideshow',
    playOneSlideShow: 'Play single date',
    playAllSlideShows: 'Play all dates',
    shareOneSlideShow: 'Link for single date',
    shareAllSlideShows: 'Link for all dates',
    downloadUpperText: 'Download image',
    downloadLowerText: '(Highest quality)',
    deleteDefault: 'Delete',
    deleteMultiple: 'Delete chosen',
    deleteRequest: 'Request deletion',
    deleting: 'Deleting',
    hideDefault: 'Hide',
    hidden: 'Show',
    hiding: 'Hiding',
    hideMultiple: 'Hide selection',
    showMultiple: 'Show selection',
    showing: 'Showing',
    copyLink: 'Copy link',
    confirm: 'Are you sure?',
    selectAll: 'Select all',
    deSelectAll: 'Unselect all',
    deleteAll: 'Delete all'
  },
  globals: {
    close: 'Close',
    linkCopied: 'The link has been copied',
    sending: 'Sending',
    authFailed: 'An error has occurred, try to login again',
    apiError: 'An error has occurred. Please make sure your internet connection is working normally',
    countDownExpiration: 'Your images will automatically be deleted in',
    days: 'days',
    day: 'day',
    hours: 'hours',
    hour: 'hour',
    galleryExpired: 'The gallery has expired',
    companyPage: 'myselfie.com',
    baseUrl: 'https://myselfie.dk',
    phoneNumber: '7196 9897',
    infoPhone: 'Tel: +45 7196 9897',
    infoEmail: 'info@myselfie.com',
    countryCode: '+45',
    yes: 'Yes',
    no: 'No'
  }
}
