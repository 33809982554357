<template>
  <div class="user-input-wrp">
    <b-form-input :disabled="isDisabled" v-on:input="raiseEvent" :type="currentType" class="inputField" :id="id" :value="value" v-on="eventMethod != null ? { keyup: eventMethod } : {}" required :autofocus="autoFocus" v-bind:style="{height: errLabelText.length > 0 ? '75px' : '70px'}"></b-form-input>
    <span :class="value.length > 0 ? 'floating-labelZeroTransition' : 'floating-label'">{{label}}</span>
    <span v-if="errLabelText.length > 0" class="floating-errLabel">{{errLabelText}}</span>

    <span v-if="isPassword" class="floating-Icon" v-on:click="passwordHidden = !passwordHidden" v-bind:style="{top: getTopIcon, marginTop: getMarginTopIcon}">
      <svg version="1.1" x="0" y="0" width="20" height="20" viewBox="0 0 21.9 18" style="enable-background:new 0 0 21,9 18;" xml:space="preserve">

<path class="pwIcon" :d="getHideIcon" />
  </svg>
    </span>
  </div>
</template>
<script>
export default {
  name: 'MysTextBox',
  props: {
    label: {
      type: String,
      default: 'LabelTxt'
    },
    value: {
      type: String,
      default: ''
    },
    keyUpEvent: {
      type: Function
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    isPassword: {
      type: Boolean,
      default: false
    },
    errLabelText: {
      type: String,
      default: ''
    },
    eventMethod: {
      type: Function,
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      passwordHidden: true
    }
  },
  computed: {
    currentType() {
      return (this.isPassword && !this.passwordHidden) ? 'text' : this.type
    },
    getTopIcon() {
      let t = 22
      if (this.errLabelText.length > 0) t += 2
      if (this.passwordHidden) t++

      return t + 'px'
    },
    getMarginTopIcon() {
      return this.errLabelText.length > 0 ? '2px' : '1px'
    },
    getHideIcon() {
      return this.passwordHidden ? 'M11,0C4.1,0,0.9,5.9,0.1,7.6C0,7.8,0,8.2,0.1,8.4C0.9,10.1,4.1,16,11,16s10.1-5.8,10.9-7.5c0.1-0.3,0.1-0.6,0-0.9C21.1,5.8,17.8,0,11,0z M11,3c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5S8.2,3,11,3z M11,5C9.3,5,8,6.3,8,8s1.3,3,3,3s3-1.3,3-3S12.7,5,11,5z' : 'M3,0C2.4,0,2,0.5,2,1c0,0.3,0.1,0.5,0.3,0.7l1.8,1.8C2.5,4.8,1.2,6.3,0.2,8.1c-0.3,0.6-0.3,1.3,0,1.9c1.2,2.1,4.5,7,10.7,7c1.8,0,3.6-0.5,5.2-1.3l2,2c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4l0,0l-16-16C3.5,0.1,3.3,0,3,0z M11,1C9.9,1,8.8,1.2,7.7,1.5l2.6,2.6C10.5,4,10.7,4,11,4c2.8,0,5,2.2,5,5c0,0.2,0,0.5-0.1,0.7l3.4,3.4c0.9-1,1.7-2,2.3-3.1c0.4-0.6,0.4-1.4,0-2C20.5,5.8,17.2,1,11,1z M6.8,6.3l1.5,1.5C8.1,8.1,8,8.6,8,9c0,1.7,1.3,3,3,3c0.4,0,0.9-0.1,1.3-0.3l1.5,1.5c-2.3,1.5-5.4,0.9-6.9-1.4c-0.4-0.6-0.6-1.3-0.8-2C5.8,8.6,6.1,7.3,6.8,6.3z'
    }
  },
  methods: {
    raiseEvent(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style scoped>

  input {
    border-width: 0;
  }

  .inputField {
    height: 70px;
  }

  .user-input-wrp {
    position: relative;
    width:100%;
  }

    .user-input-wrp input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    .form-control:-webkit-autofill,
    .form-control:-webkit-autofill:hover,
    .form-control:-webkit-autofill:focus,
    .form-control:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 100vmax white inset !important;
    }

    .user-input-wrp .inputText {
      width: 100%;
      outline: none;
      border: none;
      border-bottom: 1px solid #777;
      box-shadow: none !important;
    }

    .user-input-wrp .form-control {
      padding: 15px 18px 0 19px;
      color:black;
    }

    .user-input-wrp .floating-label {
      color: #B0A9B4;
      position: absolute;
      pointer-events: none;
      top: 22px;
      left: 20px;
      transition: 0.2s ease all;
    }

    .user-input-wrp .floating-labelZeroTransition {
      top: 5px;
      left: 20px;
      font-size: 13px;
      opacity: 1;
      color: #B0A9B4;
      position: absolute;
      pointer-events: none;
    }

    .user-input-wrp .floating-errLabel {
      color: red;
      position: absolute;
      pointer-events: none;
      top: 53px;
      left: 20px;
      transition: 0.2s ease all;
      font-size: 13px;
      opacity: 1;
    }

    .user-input-wrp .floating-Icon {
      color: #B0A9B4;
      position: absolute;
      cursor: pointer;
      right: 0;
      width:40px;
    }

    .user-input-wrp input:focus ~ .floating-label,
    .user-input-wrp input:not(:focus):valid ~ .floating-label {
      top: 5px;
      left: 20px;
      font-size: 13px;
      opacity: 1;
    }

    .user-input-wrp textarea:hover,
    input:hover,
    textarea:active,
    input:active,
    textarea:focus,
    input:focus,
    button:focus,
    button:active,
    button:hover,
    label:focus,
    .btn:active,
    .btn.active {
      outline: 0;
      -webkit-appearance: none;
      box-shadow: none !important;
    }

  .pwIcon {
    fill: #B0A9B4;
  }

</style>
