<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'app',
    mounted() {
      this.initStore()
    }
  }
</script>
