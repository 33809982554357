<template>
  <button :id="id" :class="classes" @click="$emit('click')" v-bind:style="{ opacity: isDisabled ? '0.4' : theme === 'primary' ? '1.0' : '0.9', pointerEvents: isDisabled ? 'none' : 'auto' }">
    <span style="text-align: center; line-height: 1; width: 100%; display: table;">
      <span class="stdFont" v-bind:style="{textIndent: MinWidth ? '-15px' : '0', display: secondaryText === '' ? 'inline-flex' : 'block', margin: secondaryText === '' ? '0px auto' : '0 0 0 auto'}"><slot>{{text}}</slot></span>
      <span class="stdFont" v-if="secondaryText !== ''" style="display: inline-flex;" v-bind:style="{textIndent: MinWidth ? '-15px' : '0', fontSize: smallSecondaryText ? '10px' : '1rem'}"><slot>{{secondaryText}}</slot></span>
      <span style="position: absolute; display: inline-block; right: 18px;" v-bind:style="{top: iconClass === 'smallIcon' ? '36%' : '30%'}">
        <span v-if="icon !== null" class="btn_icons2"><img :src="require(`./../assets${icon}`)" :class="iconClass" /></span>
      </span>
    </span>
  </button>
</template>

<script>
  export default {
    name: 'MysButton',
    props: {
      theme: {
        type: String,
        default: 'primary',
        validator: val => {
          return ['primary', 'secondary', 'ternary'].includes(val)
        }
      },
      id: {
        type: String,
        default: null
      },
      MinWidth: {
        type: Boolean,
        default: true
      },
      MinWidthValue: {
        type: Number,
        default: 245
      },
      icon: {
        type: String,
        default: null
      },
      iconClass: {
        type: String,
        default: 'defaultIcon'
        },
      text: {
        type: String,
        default: ''
      },
      secondaryText: {
        type: String,
        default: ''
      },
      smallSecondaryText: {
        type: Boolean,
        default: true
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes() {
        const textColor = this.theme === 'secondary' ? 'text-white' : 'text-secondary'
        return `btn-${this.theme} ${textColor}`
      }
    }
  }
</script>
<style scoped>
  button {
    border-radius: 50rem;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    height: 50px;
    width: 100%;
    border: unset;
    flex: 0 1 auto;
  }

  .btn-secondary {
    min-width: 250px;
    width: 100%;
  }

  .btn-ternary {
    min-width: 250px;
    width: 100%;
  }

  .defaultIcon {
    width: 20px;
    height: 20px;
    float: right;
    padding-right: 5px;
  }

  .smallIcon {
    width: 20px;
    height: 12px;
    float: right;
    padding-right: 5px;
  }

  .smallIconSecondary {
    width: 12px;
    height: 12px;
    float: right;
  }

  .btn_icons2 {
    top: 14px;
    left: 211px;
  }

  .user-input-wrp textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    border: none;
  }

  .stdFont {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
  }
</style>
