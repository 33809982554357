export function getShopDomainFromJwt(state) {
  const jwtOrderObject = state.jwtOrderObject
  if (!jwtOrderObject) return null

  let orderObj
  if (Object.prototype.hasOwnProperty.call(jwtOrderObject, state.currentOrderNumber)) {
    orderObj = JSON.parse(jwtOrderObject[state.currentOrderNumber])
  } else {
    const objectKeys = Object.keys(jwtOrderObject)
    if (!objectKeys.length) return null

    orderObj = JSON.parse(jwtOrderObject[objectKeys[0]])
  }

  return orderObj?.ShopDomain
}

export function getLanguageByShopDomain(shopDomain) {
  switch (shopDomain) {
    case 'myselfie-dk':
      return 'da'
    case 'flashbackbox':
    case 'myselfie-de':
      return 'de'
    case 'myselfie-se':
      return 'sv'
    default:
      return 'en'
  }
}
