<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 64 64"
       :style="{transform: 'rotate(' + transform + ')' }"
       @click="$emit('click')">
    <circle cx="32" cy="32" r="32" />
    <path fill="#fff"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.586 22.586a2 2 0 012.828 0l12 12a2 2 0 11-2.828 2.828L32 26.828 21.414 37.414a2 2 0 11-2.828-2.828l12-12z" />
  </svg>
</template>

<script>
export default {
  name: 'Chevron',
  props: {
    direction: {
      type: String,
      default: 'UP'
    }
  },
  computed: {
    transform () {
      switch (this.direction) {
        case 'UP': return '0deg'
        case 'DOWN': return '180deg'
        case 'LEFT': return '-90deg'
        case 'RIGHT': return '90deg'
        default: return '0deg'
      }
    }
  }
}
</script>

<style scoped>
  svg {
    transition: transform .2s ease;
    width: 60px;
    height: 60px;
    fill: none;
    z-index: 99;
    position: absolute;
    cursor: pointer;
    display: block;
  }
</style>
