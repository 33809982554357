<template>
<div id="mobileHeaderContainer" class="menu-mobile-header w-100 d-flex justify-content-between align-items-center pt-4" style="max-height: 50px;">
<a :href="langModule.globals.baseUrl">
  <img src="./../assets/myselfie_logo_green.svg" height="48" style="position: relative; height: 190px; left: -57px;" />
</a>
<img id="burgerIcon" src="./../assets/menu.svg" width="30" height="30" class="pointer" @click="openMenu" />
</div>
</template>
<script>
  export default {
    name: 'MobileHeader',
    methods: {
      openMenu() {
        this.$parent.showMenuFn()
      }
    }
  }
</script>
