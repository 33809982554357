import Vue from 'vue'
import VueRouter from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import GalleryView from '@/Pages/Gallery'
import LoginView from '@/Pages/Login'
import LogoutView from '@/Pages/Logout'
import ContactView from '@/Pages/Contact'
import OrdersView from '@/Pages/Orders'
import OverlayView from '@/Pages/Overlay'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    props: { Variant: 'Default' },
    meta: { stayAlive: true }
  },
  {
    path: '/logout',
    name: 'LogoutView',
    component: LogoutView,
    props: { Variant: 'Default' }
  },
  {
    path: '/',
    redirect: '/orders',
    name: 'DefaultLayout',
    component: DefaultLayout,
    meta: { stayAlive: true },
    children: [
      {
        path: '/contact',
        name: 'ContactView',
        component: ContactView,
        props: { Variant: 'Default' }
      },
      {
        path: '/orders',
        name: 'OrdersView',
        component: OrdersView,
        props: { Variant: 'Default' },
        meta: { stayAlive: true }
      },
      {
        path: '/overlay/:shopDomain/:orderNumber',
        name: 'OverlayView',
        component: OverlayView
      },
      {
        path: '/gallery/:shopDomain/:orderNumber',
        name: 'SpecificGallery',
        component: GalleryView,
        props: { Variant: 'Specific' },
        meta: { stayAlive: true },
        children: [
          {
            path: '/:shopDomain/gallery/:orderNumber/slideshow/:date/:checksum'
          },
          {
            path: '/:shopDomain/gallery/:orderNumber/slideshow/:checksum'
          }
        ]
      },
      {
        path: '/:shopDomain/:orderNumber/:date/:online/:checksum',
        name: 'OneDayGallery',
        component: GalleryView,
        props: { Variant: 'OneDay', UseOnlineParam: true }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('logRequestData')
  store.dispatch('refreshToken')
  const nullToken = store.state.accessToken === null
  const tp = to.path
  const fp = from.path
  if (tp.startsWith('/overlay') && nullToken) {
    next(`/login?redir=${tp}`)
    return
  }

  if (!tp.startsWith('/orders') && !fp.startsWith('/login')) await store.dispatch('fetchAccessToken')

  store.dispatch('registerLanguageFromUrlQuery', to)

  if (tp.includes('slideshow')) {
    next()
    return
  }

  const isLogin = tp.startsWith('/login')

  if (to.name === 'OneDayGallery') { next() } else if (!isLogin && nullToken) {
    next('/login')
  } else if (!isLogin && !nullToken) {
    next()
  } else if (isLogin && !nullToken) {
    next('/orders')
  } else { next() }
})

export default router
