export default {
  pages: {
    contact: {
      header: 'Kontakt',
      body: 'Har du brug for at komme i kontakt med os kan du kontakte os via nedenstående.'
    },
    login: {
      header: 'Log ind',
      body: 'Log ind med din email og det tilsendte password eller bestil et nyt password.',
      newPasswordRequest: 'Bestil nyt password',
      newPasswordReturn: 'Tilbage',
      newPasswordHeader: 'Nyt Password',
      newPasswordBody: 'Tryk på "Bestil nyt password" for at få tilsendt et nyt password',
      passwordSentHeader: 'Tjek din email',
      passwordSentBody: 'Vi har sendt dig en email med et nyt password. Der kan gå nogle minutter før du modtager den. Tjek evt. dit spamfilter.\n\nHar du stadig problemer?',
      passwordSMSHeader: 'Bestil sms',
      passwordSMSBody: 'Indtast dit mobilnummer for at få et nyt password på SMS.',
      passwordSendSMSHeader: 'Tjek din mobil',
      passwordSendSMSBody: 'Der er sendt en SMS med et nyt password. Der kan gå nogle minutter før du modtager den.',
      passwordChangeInfo: 'Du har for nylig ændret dit kodeord. Du kan ændre dit kodeord igen klokken',
      passwordChangeInfoSecond: ''
    },
    orders: {
      orderNumber: 'Ordre',
      date: 'Dato',
      noOrders: 'Der er i øjeblikket ingen ordre'
    },
    gallery: {
      noImagesFound: 'Der endnu ikke uploadet nogle billeder på denne ordre.\nBillederne uploades automatisk efter endt returnering.\nDu vil modtage en e-mail når dine billeder er klar.',
      deviceDisconnected: 'Den enhed du har taget billeder på, er ikke online i øjeblikket.',
      cardTitle: 'Tryk på "Download album" for at downloade billederne i højeste kvalitet',
      cardTitleMygreetings: 'Tryk på "Download album" for at download billederne i højeste kvalitet og få dine mygreetings lydfiler',
      hideHeader: 'Skjult',
      hideBody: 'Billedet er nu skjult og vil ikke\nblive vist for gæster. Det er kun\ndig der kan se billedet.',
      deleteRequestHeader: 'Anmodet om\nsletning.',
      deleteRequestBody: 'Dette billede er anmodet om sletning. Billedet vil være skjult indtil du sletter det eller gør det synligt igen.',
      slideShowOptionsHeader: 'Slideshow valgmuligheder',
      images: 'billeder',
      image: 'billede'
    },
    overlay: {
      header: 'Du kan nemt oprette et overlay i form at tekst, logo eller en grafisk ramme - både på skærmen og på de billeder som tages på myselfie.\nDa skærmens størrelse er mindre end de billeder der tages på myselfie er det to forskellige filer der skal uploades.\nDet vil sige du selv bestemmer om der skal være det samme på skærmen som på billedet',
      screenDesc: 'Her kan du lave/uploade den grafik som skal vises på skærmen (og ikke kommer på billedet). Denne billedopløsning er ',
      imageDesc: 'Her skal du lave/uploade den grafik som skal vise på billedet (og ikke kommer på skærmen). Denne billedopløsning er ',
      templateCustom: 'Mit design',
      confirmDeleteAll: 'Er du sikker på du vil slette alt?'
    }
  },
  components: {
    downloadGallery: {
      infoHeader: 'Download\nalbum',
      infoBody: 'Indtast din email, og få tilsendt et\ndownload link.',
      header: 'Tjek din\nemail',
      body: 'Du vil modtage en email med et link til\nat downloade dine billeder indenfor få\nminutter.',
      headerError: 'UPS!',
      bodyError: 'Der opstod en fejl under download af dine billeder.',
      sendLink: 'Send link'
    },
    mysTextBox: {
      emailLabel: 'Email',
      passwordLabel: 'Password',
      mobilePhoneLabel: 'Mobiltelefon',
      emailErrorLabel: 'Ugyldig email',
      emailEmptyLabel: 'Email feltet skal udfyldes',
      passwordErrorLabel: 'Forkert password',
      mobilePhoneErrorLabel: 'Ugyldigt nummer'
    },
    informDeleteRequest: {
      header: 'Anmodning\nsendt',
      body: 'Billedet er nu skjult. Det er op til lejeren af\nmyselfie om billedet skal slettes, vises eller forblive skjult.'
    },
    shareGallery: {
      header: 'Alle med dette link kan se dine billeder'
    }
  },
  menu: {
    overview: 'Mine ordrer',
    contact: 'Kontakt'
  },
  buttons: {
    login: 'Log ind',
    logginIn: 'Logger ind',
    logout: 'Log ud',
    newPassword: 'Bestil nyt password',
    newPasswordSMS: 'Bestil password på SMS',
    navToGallery: 'Galleri',
    downloadImages: 'Download album',
    shareImages: 'Del album',
    edit: 'Rediger',
    cancelEdit: 'Annuller',
    sortNewest: 'Nyeste',
    sortOldest: 'Ældste',
    startSlideShow: 'Start slideshow',
    playOneSlideShow: 'Afspil enkelt dato',
    playAllSlideShows: 'Afspil alle datoer',
    shareOneSlideShow: 'Link til enkelt dato',
    shareAllSlideShows: 'Link til alle datoer',
    downloadUpperText: 'Download billede',
    downloadLowerText: '(Højeste kvalitet)',
    deleteDefault: 'Slet',
    deleteMultiple: 'Slet valgte',
    deleteRequest: 'Anmod om sletning',
    deleting: 'Sletter',
    hideDefault: 'Skjul',
    hidden: 'Vis',
    hiding: 'Skjuler',
    hideMultiple: 'Skjul valgte',
    showMultiple: 'Vis valgte',
    showing: 'Viser',
    copyLink: 'Kopier link',
    confirm: 'Er du sikker?',
    selectAll: 'Vælg alle',
    deSelectAll: 'Fravælg alle',
    deleteAll: 'Slet alt'
  },
  globals: {
    close: 'Luk',
    linkCopied: 'Linket er kopieret',
    sending: 'Sender',
    authFailed: 'Der er sket en fejl, prøv at logge ind igen',
    apiError: 'Der er sket en fejl. Tjek venligst din internetforbindelse',
    countDownExpiration: 'Dine billeder slettes automatisk om',
    days: 'dage',
    day: 'dag',
    hours: 'timer',
    hour: 'time',
    galleryExpired: 'Galleriet er udløbet',
    companyPage: 'myselfie.dk',
    baseUrl: 'https://myselfie.dk',
    infoEmail: 'info@myselfie.dk',
    infoPhone: 'Tlf: +45 7196 9897',
    phoneNumber: '7196 9897',
    countryCode: '+45',
    yes: 'Ja',
    no: 'Nej'
  }
}
