export default {
  pages: {
    contact: {
      header: 'Kontakt',
      body: 'Falls Du Fragen hast oder Hilfe brauchst, kannst Du uns unter folgenden Kontaktdaten erreichen.\n\nMo – Fr: 09 bis 16 Uhr'
    },
    login: {
      header: 'Einloggen',
      body: 'Logge Dich mit Deiner E-Mail-Adresse und dem zugesendeten Passwort ein oder bestelle ein neues Passwort.',
      newPasswordRequest: 'Neues Passwort bestellen',
      newPasswordReturn: 'Zurück',
      newPasswordHeader: 'Neues Passwort',
      newPasswordBody: 'Klicke auf “Neues Passwort bestellen”, um ein neues Passwort zugesendet zu bekommen.',
      passwordSentHeader: 'Schaue in Deinem E-Mail-Posteingang',
      passwordSentBody: 'Wir haben Dir eine E-Mail mit Deinem neuen Passwort zugesendet. Es kann einige Minuten dauern, bis Du die E-Mail erhältst. Schaue ggf. in Deinem Spam Ordner nach.\n\nErlebst Du weiterhin Probleme?',
      passwordSMSHeader: 'Passwort per SMS bestellen',
      passwordSMSBody: 'Gebe Deine Handynummer ein, um ein neues Passwort per SMS zugesendet zu bekommen.',
      passwordSendSMSHeader: 'Schaue in Deinem SMS-Eingang',
      passwordSendSMSBody: 'Wir haben Dir eine SMS mit Deinem neuen Passwort zugesendet. Es kann einige Minuten dauern, bis Du die SMS erhältst.',
      passwordChangeInfo: 'Du hast vor Kurzem Dein Passwort geändert. Du kannst Dein Passwort wieder um',
      passwordChangeInfoSecond: 'Uhr ändern.'
    },
    orders: {
      orderNumber: 'Bestellung',
      date: 'Datum',
      noOrders: 'Derzeit sind keine Bestellungen vorhanden'
    },
    gallery: {
      noImagesFound: 'Zu dieser Bestellung wurden noch keine Bilder hochgeladen. Die Bilder werden nach Rückerhalt der Myselfiebox automatisch hochgeladen. Du erhältst eine E-Mail, sobald die Bilder abrufbar sind.',
      deviceDisconnected: 'Ihr Gerät ist momentan nicht online.',
      cardTitle: 'Klicke auf “Album herunterladen”, um die Bilder in höchster Qualität herunterzuladen.',
      cardTitleMygreetings: 'Klicke auf “Album herunterladen”, um die Bilder in höchster Qualität herunterzuladen und deine Mygreetings-Audiodateien zu erhalten.',
      hideHeader: 'Ausgeblendet',
      hideBody: "Das Bild ist jetzt ausgeblendet und wird Deinen Gästen nicht länger angezeigt. Nur Du kannst das Bild sehen.",
      deleteRequestHeader: 'Anfrage\nLöschung.',
      deleteRequestBody: 'Dieses Bild wurde zum Löschen angefordert. Das Bild bleibt verborgen, bis Sie es entweder löschen oder die Sichtbarkeit ändern.',
      slideShowOptionsHeader: 'Diashow-Optionen',
      images: 'Bilder',
      image: 'Bild'
    },
    overlay: {
      header: 'Sie können ganz einfach ein Overlay in Form von Text, einem Logo oder einem Grafikrahmen erstellen - sowohl auf dem Bildschirm als auch auf den mit mir aufgenommenen Bildern.\nDa die Größe des Bildschirms kleiner ist als die mit mir aufgenommenen Bilder, zwei verschiedene Dateien muss hochgeladen werden.\nDas heißt, Sie entscheiden selbst, ob der Bildschirm derselbe sein soll wie auf dem Bild',
      screenDesc: 'Hier können Sie die Grafiken erstellen/hochladen, die auf dem Bildschirm angezeigt werden sollen (und nicht im Bild erscheinen). Diese Bildauflösung ist ',
      imageDesc: 'Hier müssen Sie die Grafiken erstellen/hochladen, die im Bild (und nicht auf dem Bildschirm) erscheinen müssen. Diese Bildauflösung ist ',
      templateCustom: 'Mein Design',
      confirmDeleteAll: 'Möchten Sie wirklich alles löschen?'
    }
  },
  components: {
    downloadGallery: {
      infoHeader: 'Album\nherunterladen',
      infoBody: 'Gebe Deine E-Mail-Adresse ein, um einen\nDownload-Link zugesendet zu bekommen.',
      header: 'Schaue in Deinem E-Mail-Posteingang',
      body: 'Du erhältst in Kürze einen Link zum Herunterladen Deiner Bilder.',
      headerError: 'HOPPLA!',
      bodyError: 'Beim Herunterladen Ihrer Bilder ist ein Fehler aufgetreten.',
      sendLink: 'Link senden'
    },
    mysTextBox: {
      emailLabel: 'E-Mail',
      passwordLabel: 'Passwort',
      mobilePhoneLabel: 'Handynummer',
      emailErrorLabel: 'Ungültige E-Mail',
      emailEmptyLabel: 'E-Mail ist erforderlich',
      passwordErrorLabel: 'Falsches Passwort',
      mobilePhoneErrorLabel: 'Ungültige Telefonnummer'
    },
    informDeleteRequest: {
      header: 'Anfrage wurde\ngesendet',
      body: 'Das Bild ist jetzt ausgeblendet. Der Mieter des Mynies entscheidet\nob das Bild gelöscht oder verborgen bleiben soll.'
    },
    shareGallery: {
      header: 'Jeder mit diesem Link kann Deine Bilder ansehen'
    }
  },
  menu: {
    overview: 'Meine Bestellungen',
    contact: 'Kontakt'
  },
  buttons: {
    login: 'Einloggen',
    logginIn: 'Einloggen',
    logout: 'Ausloggen',
    newPassword: 'Neues Passwort bestellen',
    newPasswordSMS: 'Neues Passwort per SMS bestellen',
    navToGallery: 'Galerie',
    downloadImages: 'Album herunterladen',
    shareImages: 'Album teilen',
    edit: 'Bearbeiten',
    cancelEdit: 'Abbrechen',
    sortNewest: 'Neueste',
    sortOldest: 'Älteste',
    startSlideShow: 'Diashow starten',
    playOneSlideShow: 'Dieses Album abspielen',
    playAllSlideShows: 'Alle Alben abspielen',
    shareOneSlideShow: 'Link zu diesem Album',
    shareAllSlideShows: 'Link zu allen Alben',
    downloadUpperText: 'Bild herunterladen',
    downloadLowerText: '(Höchste Qualität)',
    deleteDefault: 'Löschen',
    deleteMultiple: 'Ausgewählte löschen',
    deleteRequest: 'Löschen anfordern',
    deleting: 'Löschen',
    hideDefault: 'Ausblenden',
    hidden: 'Anzeigen',
    hiding: 'Ausblend',
    hideMultiple: 'Ausgewählte ausblenden',
    showMultiple: 'Ausgewählte anzeigen',
    showing: 'Anzeigen',
    copyLink: 'Link kopieren',
    confirm: 'Bist Du sicher?',
    selectAll: 'Alle auswählen',
    deSelectAll: 'Alle abwählen',
    deleteAll: 'Alles löschen'
  },
  globals: {
    close: 'Schließen',
    linkCopied: 'Der Link wurde kopiert',
    sending: 'Senden',
    authFailed: 'Ein Fehler ist aufgetreten, versuchen Sie erneut, sich anzumelden',
    apiError: 'Ein Fehler ist aufgetreten. Bitte stellen Sie sicher, dass Ihre Internetverbindung normal funktioniert',
    countDownExpiration: 'Deine Bilder werden automatisch gelöscht in',
    days: 'Tage',
    day: 'Tag',
    hours: 'Stunden',
    hour: 'Stunde',
    galleryExpired: 'Die Galerie ist abgelaufen',
    companyPage: 'myselfie.de',
    baseUrl: 'https://myselfie.de',
    infoEmail: 'E-Mail: info@myselfie.de',
    infoPhone: 'Tel: +49 1525 8737127',
    phoneNumber: '1525 8737127',
    countryCode: '+49',
    yes: 'Ja',
    no: 'Nein'
  }
}
